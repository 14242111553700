export const titleCase = str => {
  var splitStr = str.toLowerCase().split(' ')
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(' ')
}

export const buildQueryUrl = (base = '', pathname = '', parameters = {}) => {
  const queryItems = []

  let url = `${base}${pathname}`

  Object.entries(parameters).forEach(([key, value]) =>
    value ? queryItems.push(`${key}=${value}`) : null
  )

  url += `${queryItems.length > 0 ? '?' + queryItems.join('&') : ''}`

  return url
}

export const buildApiUrl = (pathname, parameters) =>
  buildQueryUrl(`${process.env.API_V2_URL}`, pathname, parameters)

export const isDecimal = value => {
  return /^\d*\.?\d*$/.test(value)
}

export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&')
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const isMaintMode = () => {
  return process.env.MAINT_MODE === 'true'
}
