import { createGlobalStyle } from 'styled-components'

const globalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.bg} !important;

    color: ${({ theme }) => theme.colors.text} !important;
  }

  h1 {
    /* identical to box height, or 100% */
    letter-spacing: -0.05em;
  }

  h2 {
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.05em;
  }
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 40px !important;
    line-height: 40px !important;
    color: #101010 !important;
    letter-spacing: -0.04em;
  }
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.04em;
  }
  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 21px !important;
    line-height: 27px !important;
    color: #101010 !important;
  }
  h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px;
  }

  p, .p{
    color: ${({ theme }) => theme.colors.text};
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.56px;
    line-height: 30px;
    margin-bottom: 0;
  }

  strong {
    font-weight: 600 !important;
  }

  ul,.ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }


  a {
    color: ${({ theme }) => theme.colors.text} !important;
    transition: all 0.3s ease-out;
    &:hover, &:active, &:focus{
      color: ${({ theme }) => theme.colors.secondary} !important;
      text-decoration: none!important;
      outline: none !important;
    }
  }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }

    // PHONE STYLING
    @media only screen and (max-width: 576px) {
      h1 {
        /* H1 Phone */
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        /* or 112% */
        letter-spacing: -0.05em;
      }
      h2 {
        /* H2 Phone */
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 54px;
        /* or 135% */
        letter-spacing: -0.05em;
      }
    }
`

export default globalStyle
