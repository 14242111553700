import {
  LOAD_EXCH_MARKET_DATA,
  SET_EXCH_DATA,
  SET_EXCH_DETAIL,
  SET_EXCH_MARKET_DATA,
  SET_HEADER_COUNTS,
  SET_FEES_TOTAL_COST,
  SET_FEES_ORDER_SIDE,
} from '../actions/exchDataActions'

const initialState = {
  exchData: [],
  exchDetail: false,
  exchMarketData: [],
  totalRecords: 0,
  headerCounts: false,
  loadingMarketData: false,
  totalCost: 0,
  orderSide: 1,
}

const exchDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXCH_DATA:
      return {
        ...state,
        exchData: action.exchData,
      }
    case SET_EXCH_DETAIL:
      return {
        ...state,
        exchDetail: action.exchDetail,
      }
    case LOAD_EXCH_MARKET_DATA:
      return {
        ...state,
        loadingMarketData: true,
      }
    case SET_EXCH_MARKET_DATA:
      return {
        ...state,
        loadingMarketData: false,
        exchMarketData: action.exchMarketData,
        totalRecords: action.totalRecords,
      }
    case SET_HEADER_COUNTS:
      return {
        ...state,
        headerCounts: action.headerCounts,
      }
    case SET_FEES_TOTAL_COST:
      return {
        ...state,
        totalCost: action.totalCost,
      }
    case SET_FEES_ORDER_SIDE:
      return {
        ...state,
        orderSide: action.orderSide,
      }
    default:
      return state
  }
}

export default exchDataReducer
