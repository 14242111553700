import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import backend from 'i18next-http-backend'
import resourcesToBackend from 'i18next-resources-to-backend'

i18next
  .use(LanguageDetector)
  .use(backend)
  .use(initReactI18next)
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`../../locales/${language}/${namespace}.json`)
        .then(resources => {
          // console.log('AAAA', resources)
          callback(null, resources)
        })
        .catch(error => {
          // console.log('ERROR', error)
          callback(error, null)
        })
    })
  )
  .init({
    partialBundledLanguages: true,
    fallbackLng: 'en',
    // resources: {
    //   es: {
    //     landing: require('../../locales/es/landing.json'),
    //     // translation: require('../../locales/es/translation.json'),
    //   },
    //   en: {
    //     landing: require('../../locales/en/landing.json'),
    //     // test: require('../../locales/en/test.json'),
    //   },
    // },
    ns: ['landing'],
    defaultNS: 'landing',
    nsSeparator: '.',
    keySeparator: '.',
    returnObjects: true,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      useSuspense: false,
    },
  })

i18next.languages = ['en', 'es']

export default i18next
