import React from 'react'
import styled from 'styled-components'
import {
  space,
  border,
  typography,
  shadow,
  flexbox,
  layout,
} from 'styled-system'
import { useSelector, useDispatch } from 'react-redux'
import * as userActions from '../../store/actions/userActions'

const SwitchStyled = styled.div`
  display: inline-block;
  cursor: pointer;
  width: 3.5rem;
  height: 33px;
  border-radius: 17px;
  transition: all 0.3s ease-out;
  background: ${({ active, theme, bg }) =>
    active ? theme.colors[bg] : `#eae9f2`};
  position: relative;
  margin: 0 0.5rem;
  white-space: nowrap;
  ${border};
  ${space};
  ${typography};
  ${shadow};
  ${flexbox};
  ${layout};
`

const Round = styled.span`
  width: 21px;
  height: 21px;
  background-color: ${({ active }) => (active ? `#ffffff` : `#696969`)};
  position: absolute;
  transition: all 0.3s ease-out;
  left: ${({ active }) => (active ? `calc(100% - 33px)` : `0`)};
  margin-left: 6px;
  top: 0;
  margin-top: 6px;
  transition: 0.4s;
  border-radius: 500px;
  pointer-events: none;
`

const Switch = ({
  bg = 'secondary',
  comingSoon,
  onClick = () => {},
  ...rest
}) => {
  const dispatch = useDispatch()
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const csrfCookie = useSelector(state => state.user.csrfCookie)
  const csrfCoinigy = useSelector(state => state.user.csrf_token)
  const userSettings = useSelector(state => state.user.user_settings)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkModeSettings =
    typeof userSettings !== 'undefined' ? userSettings.dark_mode : false
  let newToken =
    /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie) !== null
      ? /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie)[1]
      : ''

  const handleSwitch = async () => {
    if (csrfCookie !== csrfCoinigy) {
      dispatch(userActions.fetchCsrf())
    }
    dispatch(userActions.setDarkMode(!userDarkModeSelector))
    if (userSessionExpired === false) {
      await dispatch(
        userActions.saveUserDarkMode(newToken, !userDarkModeSettings)
      )
      await dispatch(userActions.fetchUserSettings(newToken))
    }
    onClick()
  }

  if (comingSoon === true) {
    return (
      <SwitchStyled
        bg={bg}
        {...rest}
        onClick={handleSwitch}
        active={userDarkModeSelector}
      >
        <Round active={userDarkModeSelector} />
      </SwitchStyled>
    )
  }
  return (
    <SwitchStyled
      bg={bg}
      {...rest}
      onClick={handleSwitch}
      active={
        userSessionExpired === true
          ? userDarkModeSelector
          : userDarkModeSettings
      }
    >
      <Round
        active={
          userSessionExpired === true
            ? userDarkModeSelector
            : userDarkModeSettings
        }
      />
    </SwitchStyled>
  )
}

export default Switch
