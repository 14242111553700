import { rgba } from 'polished'
import { breakpoints } from './breakpoints'

const defaultColors = {
  primary: '#3377C6',
  primaryLight: '#3B96FF',
  secondary: '#293138',
  white: '#ffffff',
  dark: '#19191b',
  black: '#000000',
  yellow: '#fedc5a',
  ash: '#413e65',
  green: '#27cb94',
  red: '#f2587c',
  info: '#7a9dff',
  mediumGray: '#9f9f9f',
  lightGray: '#FAFAFA',
  darkGray: '#2B2F36',
  light: '#f8f9fa',
  bgDarkGray: '#191B20',
}

const colors = {
  primary: defaultColors.primary,
  primaryLight: defaultColors.primaryLight,
  secondary: defaultColors.secondary,
  light: defaultColors.white,
  lightShade: rgba(defaultColors.white, 0.75),
  lighter: rgba(defaultColors.white, 0.5),
  dark: defaultColors.dark,
  black: defaultColors.black,
  darkShade: rgba(defaultColors.dark, 0.75),
  yellow: defaultColors.yellow,
  ash: defaultColors.ash,
  green: defaultColors.green,
  red: defaultColors.red,
  bg: defaultColors.light,
  border: rgba(defaultColors.ash, 0.115),
  shadow: rgba(defaultColors.ash, 0.175),
  heading: defaultColors.dark,
  text: rgba(defaultColors.dark, 0.75),
  warning: defaultColors.yellow,
  success: defaultColors.green,
  info: defaultColors.info,
  lightGray: defaultColors.lightGray,
  mediumGray: defaultColors.mediumGray,
  darkGray: defaultColors.darkGray,
  bgDarkGray: defaultColors.bgDarkGray,

  modes: {
    dark: {
      primary: defaultColors.primary,
      primaryLight: defaultColors.primaryLight,
      secondary: defaultColors.secondary,
      light: defaultColors.white,
      lightShade: rgba(defaultColors.white, 0.75),
      dark: defaultColors.dark,
      black: defaultColors.black,
      darkShade: rgba(defaultColors.dark, 0.75),
      ash: defaultColors.ash,
      bg: defaultColors.dark,
      border: rgba(defaultColors.ash, 0.115),
      shadow: rgba(defaultColors.ash, 0.175),
      heading: defaultColors.white,
      text: rgba(defaultColors.white, 0.75),
      warning: defaultColors.yellow,
      success: defaultColors.green,
      info: defaultColors.info,
      lightGray: defaultColors.lightGray,
      mediumGray: defaultColors.mediumGray,
      darkGray: defaultColors.darkGray,
    },
  },
}

const theme = {
  initialColorModeName: 'light',
  colors: colors,
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints: [
    `${breakpoints.sm}px`,
    `${breakpoints.md}px`,
    `${breakpoints.lg}px`,
    `${breakpoints.xl}px`,
  ],
}

export default theme
