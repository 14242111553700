import React from 'react'
import { withTheme } from 'styled-components'

import Select from 'react-select'

const defaultOptions = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
]

const getCustomStyles = (theme, userDarkMode) => {
  return {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? theme.colors.secondary : theme.colors.dark,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: theme.colors.border,
      },
    }),
    control: (provided, state) => {
      return {
        ...provided,
        backgroundColor: 'transparent',
        border: `0px solid transparent !important`,
        // border: state.menuIsOpen || state.isFocused
        //? `1px solid ${theme.colors.secondary} !important`
        //: `1px solid ${theme.colors.border} !important`,
        borderRadius: 5,
        //padding: "0.275rem 0.3rem",
        paddingRight: '0px !important',
        width: '100%',
        outline: 'none',
        height: 40,
        minHeight: 40,
        boxShadow: 'none',
      }
    },
    singleValue: (provided, state) => {
      const color =
        userDarkMode === true ? 'rgba(255,255,255,0.75)' : 'rgba(25,25,27,0.75)'
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, color, opacity, transition }
    },
  }
}

const SelectStyled = ({
  theme,
  name = 'item',
  options = defaultOptions,
  userDarkMode,
  ...rest
}) => {
  return (
    <Select
      styles={getCustomStyles(theme, userDarkMode)}
      defaultValue={options[0]}
      name={name}
      options={options}
      {...rest}
    />
  )
}

export default withTheme(SelectStyled)
