import CurrencyModel from '../../models/CurrencyModel'
import CurrencyTopModel from '../../models/CurrencyTopModel'
import MarketModel from '../../models/MarketModel'
import OHLCModel from '../../models/OHLCModel'
import { buildApiUrl } from '../../utils/helperFn'

export const SET_CURRENCIES = 'SET_CURRENCIES'
export const SET_EXCH_CURRS = 'SET_EXCH_CURRS'
export const LOAD_CURRENCIES = 'LOAD_CURRENCIES'
export const SET_CURRENCY_DETAIL = 'SET_CURRENCY_DETAIL'
export const SET_BTC_EXCH_RATE = 'SET_BTC_EXCH_RATE'
export const SET_TABLE_EXCH_RATE = 'SET_TABLE_EXCH_RATE'
export const SET_CURR_OHLC = 'SET_CURR_OHLC'
export const SET_CURR_MARKETS = 'SET_CURR_MARKETS'
export const LOAD_CURR_MARKETS = 'LOAD_CURR_MARKETS'
export const SET_DEFAULT_MARKET = 'SET_DEFAULT_MARKET'

export const fetchCurrencies = (
  searchTerm,
  sortBy,
  sortOrder,
  pageSize,
  pageNumber,
  miniChartRange
) => {
  let endpoint = buildApiUrl('/public/currencies/summaries', {
    SearchTerm: searchTerm,
    Sort: sortBy,
    SortOrder: sortOrder,
    PageSize: pageSize,
    PageNumber: pageNumber,
    Range: miniChartRange,
  })

  return async (dispatch, getState) => {
    dispatch({ type: LOAD_CURRENCIES })
    const response = await fetch(endpoint)

    let resData = await response.json()

    const loadedCurrencies = []
    if (searchTerm !== null && resData.result !== null) {
      for (const currency in resData.result) {
        loadedCurrencies.push(
          new CurrencyTopModel(
            resData.result[currency].rank,
            resData.result[currency].currName,
            resData.result[currency].currCode,
            resData.result[currency].btcPrice,
            resData.result[currency].chartData,
            resData.result[currency].volume24HourBtc,
            resData.result[currency].volume1HourBtc,
            resData.result[currency].marketCapUsd,
            resData.result[currency].slug,
            resData.result[currency].percentChange,
            resData.totalRecords
            // resData.result[currency].availableSupply,
            // resData.result[currency].miningTotalCirculation,
            // resData.result[currency].btcVolume1Hour
          )
        )
      }
    }
    if (searchTerm === null) {
      for (const currency in resData.result) {
        loadedCurrencies.push(
          new CurrencyTopModel(
            resData.result[currency].rank,
            resData.result[currency].currName,
            resData.result[currency].currCode,
            resData.result[currency].btcPrice,
            resData.result[currency].chartData,
            resData.result[currency].volume24HourBtc,
            resData.result[currency].volume1HourBtc,
            resData.result[currency].marketCapUsd,
            resData.result[currency].slug,
            resData.result[currency].percentChange,
            resData.totalRecords
            // resData.result[currency].availableSupply,
            // resData.result[currency].miningTotalCirculation,
            // resData.result[currency].btcVolume1Hour
          )
        )
      }
    }
    // console.log('Loaded Currencies OK', loadedCurrencies);
    dispatch({
      type: SET_CURRENCIES,
      currencies: loadedCurrencies,
    })
  }
}

export const fetchExchCurrs = (
  sortBy,
  sortOrder,
  pageSize,
  pageNumber,
  miniChartRange,
  searchTerm
) => {
  let endpoint = buildApiUrl('/public/currencies/summaries', {
    Sort: sortBy,
    SortOrder: sortOrder,
    PageSize: pageSize,
    PageNumber: pageNumber,
    Range: miniChartRange,
    SearchTerm: searchTerm,
  })

  return async (dispatch, getState) => {
    dispatch({ type: LOAD_CURRENCIES })
    const response = await fetch(endpoint)

    let resData = await response.json()

    const loadedCurrencies = []
    if (searchTerm !== null && resData.result !== null) {
      for (const currency in resData.result) {
        loadedCurrencies.push(
          new CurrencyTopModel(
            resData.result[currency].rank,
            resData.result[currency].currName,
            resData.result[currency].currCode,
            resData.result[currency].btcPrice,
            resData.result[currency].chartData,
            resData.result[currency].volume24HourBtc,
            resData.result[currency].volume1HourBtc,
            resData.result[currency].marketCapUsd,
            resData.result[currency].slug,
            resData.result[currency].percentChange,
            resData.totalRecords
            // resData.result[currency].availableSupply,
            // resData.result[currency].miningTotalCirculation,
            // resData.result[currency].btcVolume1Hour
          )
        )
      }
    }
    // if (searchTerm === null) {
    //   for (const currency in resData.result) {
    //     loadedCurrencies.push(
    //       new CurrencyTopModel(
    //         resData.result[currency].rank,
    //         resData.result[currency].currName,
    //         resData.result[currency].currCode,
    //         resData.result[currency].btcPrice,
    //         resData.result[currency].chartData,
    //         resData.result[currency].volume24HourBtc,
    //         resData.result[currency].volume1HourBtc,
    //         resData.result[currency].marketCapUsd,
    //         resData.result[currency].slug,
    //         resData.result[currency].percentChange,
    //         resData.totalRecords
    //         // resData.result[currency].availableSupply,
    //         // resData.result[currency].miningTotalCirculation,
    //         // resData.result[currency].btcVolume1Hour
    //       )
    //     )
    //   }
    // }
    // console.log('Loaded Currencies OK', loadedCurrencies);
    dispatch({
      type: SET_EXCH_CURRS,
      exchCurrs: loadedCurrencies,
    })
  }
}

export const fetchCurrencyDetail = currCode => {
  let endpoint = buildApiUrl(`/public/currencies/${currCode}/summaries`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint)

    let resData = await response.json()
    // console.log('RESDATA', resData)
    let loadedCurr
    if (resData.result !== null) {
      loadedCurr = new CurrencyModel(
        resData.result.data.currId,
        resData.result.data.currCode,
        resData.result.data.currName,
        resData.result.data.fiat,
        resData.result.data.description,
        resData.result.data.isTrackable,
        resData.result.data.walletFieldName,
        resData.result.data.availableSupply,
        resData.result.data.miningTotalCirculation,
        resData.result.data.marketCap,
        resData.result.data.volume24HourBtc,
        resData.result.data.volume1HourBtc,
        resData.result.data.btcPrice,
        resData.result.prevSlug,
        resData.result.nextSlug,
        resData.result.prevSlugName,
        resData.result.nextSlugName,
        resData.result.data.urlWebsite
      )
    }

    // console.log('Loaded CURR OK', loadedCurr);
    dispatch({
      type: SET_CURRENCY_DETAIL,
      currency: loadedCurr,
    })
  }
}

export const fetchBtcExchRate = userCurrency => {
  let endpoint = buildApiUrl(`/public/convert/BTC/${userCurrency}`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint)

    let resData = await response.json()
    let btcExchRate
    btcExchRate = resData.result

    dispatch({
      type: SET_BTC_EXCH_RATE,
      btcExchRate: btcExchRate,
    })
  }
}

export const fetchTableExchRate = (tableCurr, userCurrency) => {
  let endpoint = buildApiUrl(`/public/convert/${tableCurr}/${userCurrency}`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint)

    let resData = await response.json()
    let tableExchRate
    tableExchRate = resData.result

    dispatch({
      type: SET_TABLE_EXCH_RATE,
      tableExchRate: tableExchRate,
    })
  }
}

export const fetchCurrMarkets = (
  baseCurrCode,
  quoteCurrCode,
  sortBy,
  sortOrder,
  pageSize,
  pageNumber,
  miniChartRange
) => {
  let endpoint = buildApiUrl(`/public/markets/market-summaries`, {
    BaseCurrCode: baseCurrCode,
    QuoteCurrCode: quoteCurrCode,
    Sort: sortBy,
    SortOrder: sortOrder,
    PageSize: pageSize,
    PageNumber: pageNumber,
    Range: miniChartRange,
  })

  return async (dispatch, getState) => {
    dispatch({ type: LOAD_CURR_MARKETS })
    const response = await fetch(endpoint)

    let resData = await response.json()
    // console.log('CURRMARKETS', resData)
    let defaultMarket
    const loadedData = []
    for (const market in resData.result) {
      loadedData.push(
        new MarketModel(
          resData.result[market].exchmktId,
          resData.result[market].displayName,
          resData.result[market].exchId,
          resData.result[market].exchName,
          resData.result[market].exchCode,
          resData.result[market].marketId,
          resData.result[market].marketName,
          resData.result[market].baseCurrencyCode,
          resData.result[market].quoteCurrencyCode,
          resData.result[market].baseIsFiat,
          resData.result[market].quoteIsFiat,
          resData.result[market].baseCurrencyName,
          resData.result[market].quoteCurrencyName,
          resData.result[market].quantityPrecision,
          resData.result[market].pricePrecision,
          resData.result[market].lastTradePrice,
          resData.result[market].volume24,
          resData.result[market].miniChartData,
          resData.result[market].exchSlug,
          resData.result[market].volume24Btc,
          resData.result[market].btcPrice,
          resData.result[market].percentChange,
          resData.totalRecords
        )
      )
    }
    defaultMarket = loadedData[0]
    // console.log('Loaded markets data OK', defaultMarket);
    dispatch({
      type: SET_CURR_MARKETS,
      currMarkets: loadedData,
      dafaultMarket: defaultMarket,
    })
  }
}

export const fetchCurrOHLC = (currCode, quoteCurr) => {
  let endpoint = buildApiUrl(`/public/currencies/${currCode}/summaries`, {
    quote: quoteCurr,
    timeframe: 'all',
  })

  let loadedOhlc = []

  let ohlcQuoteCurr = quoteCurr

  return async (dispatch, getState) => {
    const response = await fetch(endpoint)

    let resData = await response.json()

    if (
      resData.success === true &&
      resData.result.data.chartData !== null &&
      resData.result.data.chartData.candles.length > 0
    ) {
      for (const item in resData.result.data.chartData.candles) {
        loadedOhlc.push(
          new OHLCModel(
            resData.result.data.chartData.candles[item].open,
            resData.result.data.chartData.candles[item].high,
            resData.result.data.chartData.candles[item].low,
            resData.result.data.chartData.candles[item].close,
            resData.result.data.chartData.candles[item].volume,
            resData.result.data.chartData.candles[item].startTime,
            resData.result.data.chartData.candles[item].endTime
          )
        )
      }
    }
    loadedOhlc.reverse()

    dispatch({
      type: SET_CURR_OHLC,
      ohlc: loadedOhlc,
      ohlcQuoteCurr: ohlcQuoteCurr,
    })
  }
}

export const setDefaultMarket = defaultMarket => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_DEFAULT_MARKET,
      defaultMarket: defaultMarket,
    })
  }
}
