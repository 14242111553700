import { useEffect } from 'react'

const useScript = maintMode => {
  useEffect(() => {
    if (maintMode) {
      const js = `
        (function(m,a,i,l,e,r){ m['MailerLiteObject']=e;function f(){
        var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);}
        f.q=f.q||[];m[e]=m[e]||f.bind(f.q);m[e].q=m[e].q||f.q;r=a.createElement(i);
        var _=a.getElementsByTagName(i)[0];r.async=1;r.src=l+'?v'+(~~(new Date().getTime()/1000000));
        _.parentNode.insertBefore(r,_);})(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');
        
        var ml_account = ml('accounts', '1887272', 'q6x0d1k8b7', 'load');
      `
      const script = document.createElement('script')
      const scriptText = document.createTextNode(js)
      script.appendChild(scriptText)
      document.body.appendChild(script)
    }

    // const script2 = document.createElement("script");
    // script2.src = "https://static.mailerlite.com/js/w/webforms.min.js?CODE";
    // document.body.appendChild(script2);
  }, [maintMode])
}

export default useScript
