import React from 'react'
import { Helmet } from 'react-helmet-async'
import metaConfig from './MetaConfig'
// import {Link} from 'gatsby'

const MetaData = ({ page, param }) => {
  const meta = metaConfig(page, param)

  return (
    <Helmet>
      <title>{meta.title_tag}</title>
      <link rel="canonical" hreflang="en" key="en" to={meta.en_page_url} />
      <link rel="alternate" hreflang="es" key="es" to={meta.es_page_url} />
      <meta name="title" content={meta.meta_title}></meta>
      <meta name="description" content={meta.meta_description}></meta>

      <meta property="og:type" content={meta.meta_og_type}></meta>
      <meta property="og:url" content={meta.meta_og_url}></meta>
      <meta property="og:title" content={meta.meta_og_title}></meta>
      <meta property="og:description" content={meta.meta_og_description}></meta>
      <meta property="og:image" content={meta.meta_og_image}></meta>

      <meta property="twitter:card" content={meta.meta_tw_card}></meta>
      <meta property="twitter:url" content={meta.meta_tw_url}></meta>
      <meta property="twitter:title" content={meta.meta_tw_title}></meta>
      <meta
        property="twitter:description"
        content={meta.meta_tw_description}
      ></meta>
      <meta property="twitter:image" content={meta.meta_tw_image}></meta>
    </Helmet>
  )
}

export default MetaData
