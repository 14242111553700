import ExchDataModel from '../../models/ExchDataModel'
import ExchDetailModel from '../../models/ExchDetailModel'
import MarketModel from '../../models/MarketModel'
import { buildApiUrl } from '../../utils/helperFn'

export const LOAD_EXCH_MARKET_DATA = 'LOAD_EXCH_MARKET_DATA'
export const SET_EXCH_DATA = 'SET_EXCH_DATA'
export const SET_EXCH_DETAIL = 'SET_EXCH_DETAIL'
export const SET_EXCH_MARKET_DATA = 'SET_EXCH_MARKET_DATA'
export const SET_HEADER_COUNTS = 'SET_HEADER_COUNTS'
export const SET_FEES_TOTAL_COST = 'SET_FEES_TOTAL_COST'
export const SET_FEES_ORDER_SIDE = 'SET_FEES_ORDER_SIDE'

export const fetchHeaderCounts = () => {
  let endpoint = buildApiUrl(`/public/stats`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint)
    let resData = await response.json()
    let headerCounts = resData.result

    dispatch({
      type: SET_HEADER_COUNTS,
      headerCounts: headerCounts,
    })
  }
}

export const fetchExchData = () => {
  let endpoint = buildApiUrl(`/public/exchanges`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint)

    let resData = await response.json()
    const loadedData = []
    for (const exchId in resData.result) {
      // remove LSCX from exchanges list
      if (resData.result[exchId].exchId !== 91) {
        loadedData.push(
          new ExchDataModel(
            exchId,
            resData.result[exchId].exchName,
            resData.result[exchId].exchUrl,
            resData.result[exchId].exchCode,
            resData.result[exchId].heatIndex,
            resData.result[exchId].currencyCount,
            resData.result[exchId].marketCount,
            resData.result[exchId].exchVolume24,
            resData.result[exchId].exchVolume1,
            resData.result[exchId].exchBalanceEnabledV2,
            resData.result[exchId].exchTradeEnabledV2,
            resData.result[exchId].hasSpotTrading,
            resData.result[exchId].hasFuturesTrading,
            resData.result[exchId].exchHasWhitelisting,
            resData.result[exchId].slug,
            resData.result[exchId].trafficRank,
            resData.result[exchId].trafficRank90dChange
          )
        )
      }
    }
    // console.log('Loaded Exchange data OK', loadedData);
    dispatch({
      type: SET_EXCH_DATA,
      exchData: loadedData,
    })
  }
}

export const fetchExchDetail = exchCode => {
  let endpoint = buildApiUrl(`/public/exchanges/${exchCode}`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint)

    let resData = await response.json()
    let loadedExchange

    loadedExchange = new ExchDetailModel(
      // remove this exchId string
      'exchId',
      resData.result.data.exchName,
      resData.result.data.exchJson,
      resData.result.data.exchUrl,
      resData.result.data.exchCode,
      resData.result.data.heatIndex,
      resData.result.data.currencyCount,
      resData.result.data.marketCount,
      resData.result.data.exchVolume24,
      // enFaqsKeys,
      // enFaqsValues,
      // esFaqsKeys,
      // esFaqsValues,
      resData.result.data.exchBalanceEnabledV2,
      resData.result.data.exchTradeEnabledV2,
      resData.result.data.hasSpotTrading,
      resData.result.data.hasFuturesTrading,
      resData.result.prevSlug,
      resData.result.nextSlug,
      resData.result.prevSlugName,
      resData.result.nextSlugName
    )

    // console.log('LOADED EXCH', loadedExchange);
    dispatch({
      type: SET_EXCH_DETAIL,
      exchDetail: loadedExchange,
    })
  }
}

export const fetchExchMarketData = (
  searchTerm,
  exchCode,
  sortBy,
  sortOrder,
  pageSize,
  pageNumber,
  miniChartRange
) => {
  let endpoint = buildApiUrl(`/public/markets/market-summaries`, {
    SearchTerm: searchTerm,
    exchange: exchCode,
    Range: miniChartRange,
    Sort: sortBy,
    SortOrder: sortOrder,
    PageSize: pageSize,
    PageNumber: pageNumber,
  })

  return async (dispatch, getState) => {
    dispatch({ type: LOAD_EXCH_MARKET_DATA })
    const response = await fetch(endpoint)

    let resData = await response.json()
    const loadedData = []
    let totalRecords = resData.totalRecords
    for (const market in resData.result) {
      loadedData.push(
        new MarketModel(
          resData.result[market].exchmktId,
          resData.result[market].displayName,
          resData.result[market].exchId,
          resData.result[market].exchName,
          resData.result[market].exchCode,
          resData.result[market].marketId,
          resData.result[market].marketName,
          resData.result[market].baseCurrencyCode,
          resData.result[market].quoteCurrencyCode,
          resData.result[market].baseIsFiat,
          resData.result[market].quoteIsFiat,
          resData.result[market].baseCurrencyName,
          resData.result[market].quoteCurrencyName,
          resData.result[market].quantityPrecision,
          resData.result[market].pricePrecision,
          resData.result[market].lastTradePrice,
          resData.result[market].volume24,
          resData.result[market].miniChartData,
          resData.result[market].exchSlug,
          resData.result[market].volume24Btc,
          resData.result[market].btcPrice,
          resData.result[market].percentChange
        )
      )
    }
    // console.log('Loaded Exchange Market data OK', loadedData);
    dispatch({
      type: SET_EXCH_MARKET_DATA,
      exchMarketData: loadedData,
      totalRecords: totalRecords,
    })
  }
}

export const setFeesTotalCost = totalCost => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_FEES_TOTAL_COST,
      totalCost: totalCost,
    })
  }
}

export const setFeesOrderSide = orderSide => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_FEES_ORDER_SIDE,
      orderSide: orderSide,
    })
  }
}
