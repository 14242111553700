class CurrencyTop {
  constructor(
    rank,
    name,
    code,
    lastPriceBtc,
    chartData,
    volume24h,
    volume1h,
    marketCap,
    slug,
    percentChange,
    totalRecords
  ) {
    this.rank = typeof rank === 'undefined' ? '' : rank
    this.name = typeof name === 'undefined' ? '' : name
    this.code = typeof code === 'undefined' ? '' : code
    this.lastPriceBtc = typeof lastPriceBtc === 'undefined' ? '' : lastPriceBtc
    this.chartData = typeof chartData === 'undefined' ? '' : chartData
    this.volume24h = typeof volume24h === 'undefined' ? '' : volume24h
    this.volume1h = typeof volume1h === 'undefined' ? '' : volume1h
    this.marketCap = typeof marketCap === 'undefined' ? '' : marketCap
    this.slug = typeof slug === 'undefined' ? '' : slug
    this.percentChange =
      typeof percentChange === 'undefined' ? '' : percentChange
    this.totalRecords = typeof totalRecords === 'undefined' ? '' : totalRecords
  }
}

export default CurrencyTop
