import React, { useEffect, useContext } from 'react'

import GlobalContext from '../../context/GlobalContext'

const PageWrapper = ({
  location,
  children,
  headerDark = false,
  footerDark = false,
}) => {
  const gContext = useContext(GlobalContext)

  useEffect(() => {
    gContext.saveLocation(location)
    if (headerDark) {
      gContext.goHeaderDark()
    } else {
      gContext.goHeaderLight()
    }

    if (footerDark) {
      gContext.goFooterDark()
    } else {
      gContext.goFooterLight()
    }
  }, [gContext, headerDark, footerDark, location])

  return <>{children}</>
}

export default PageWrapper
