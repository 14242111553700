exports.supportedLanguages = ["en", "es"];

exports.defaultLanguage = "en";

exports.exchanges = [
  "lightspeed-crypto",
  "coinbase-pro",
  "binance",
  "bittrex",
  "bitstamp",
  "kraken",
  "bitfinex",
  "poloniex",
  "coinbase-prime",
  "binance-futures",
  "hitbtc",
  "binance-us",
  "coinmate",
  "paymium",
  "okex",
  "kucn",
  "ftx-international",
  "ftx-us",
  "gateio",
  "okcoin-futures",
  "cexio",
  "bitmex",
  "gemini",
  "itbit",
  "the-rock",
  "indodax",
  "bleutrade",
  "yobit",
  "vaultoro",
  "mercadobitcoin",
  "korbit",
  "exmo",
  "coincheck",
  "btc-markets",
  "bitflyer",
  "coinone",
  "hubi",
  "deribit",
  "ogre",
  "zb",
  "uniswapv2",
  "ripio",
  "iluvatar",
  "bybit",
  "dydx",
  "sushiswap"
];

exports.currencies = [
  "btc-bitcoin",
  "eth-ethereum",
  "usdt-tether",
  "bnb-binance-coin",
  "hex-hex",
  "xrp-ripple",
  "ada-ada",
  "busd-busd",
  "doge-dogecoin",
  "avax-avax",
  "dot-dot",
  "shib-shib",
  "wbtc-wbtc",
  "steth-steth",
  "dai-dai",
  "trx-tron",
  "matic-matic",
  "near-near",
  "cro-cro",
  "ltc-litecoin",
  "bch-bitcoin-cash",
  "leo-leo",
  "atom-atom",
  "link-chainlink",
  "ftt-ftt",
  "algo-algo",
  "xlm-stellar-lumen",
  "btcb-bitcoin-bep2",
  "xmr-monero",
  "etc-ethereum-classic",
  "ape-ape",
  "vet-vet",
  "icp-icp",
  "hbar-hbar",
  "fil-fil",
  "toncoin-toncoin",
  "mana-mana",
  "egld-egld",
  "frax-frax",
  "sand-sand",
  "xtz-xtz",
  "theta-theta",
  "cake-cakecoin",
  "dfi-defichain",
  "wbnb-wbnb",
  "eos-eos",
  "aave-aave",
  "klay-klaytn",
  "ftm-ftm",
  "bttold-bttold",
  "zec-zcash",
  "hnt-hnt",
  "axs-axs",
  "kcs-kucoin-shares",
  "flow-flow",
  "btt-btt",
  "waves-waves",
  "hbtc-hbtc",
  "ht-ht",
  "grt-the-graph",
  "bsv-bsv",
  "xec-xec",
  "cvx-cvx",
  "mkr-maker",
  "tusd-tusd",
  "neo-neo",
  "qnt-qnt",
  "nexo-nexo",
  "crv-crv",
  "okb-okb",
  "ksm-ksm",
  "chz-chz",
  "celo-celo-native-asset",
  "gala-gala",
  "zil-zilliqa",
  "lrc-loopring",
  "usdp-usdp",
  "pax-pax",
  "usdn-neutrino-usd",
  "dash-dash",
  "enj-enj",
  "mina-mina",
  "ldo-ldo",
  "bat-basic-attention-token",
  "amp-amp",
  "xdc-xdc",
  "xem-nem",
  "ar-arcoin",
  "kava-kava",
  "dcr-decred",
  "comp-comp",
  "tfuel-tfuel",
  "t-t",
  "nano-nano",
  "paxg-paxg",
  "yfi-yfi",
  "kda-kadena",
  "looks-looks",
  "zrx-0x",
  "scrt-scrt",
  "glmr-glmr",
  "audio-audio",
  "icx-icon",
  "gno-gno",
  "rose-rose",
  "qtum-qtum",
  "1inch-1inch",
  "snx-snx",
  "iotx-iotx",
  "lpt-lpt",
  "knc-kyber-network",
  "omg-omisego",
  "bnt-bancor",
  "bora-bora",
  "skl-skl",
  "gt-gt",
  "xym-xym",
  "btg-bitcoin-gold",
  "cel-cel",
  "ankr-ankr",
  "elon-elon",
  "xaut-xaut",
  "srm-srm",
  "astr-astr",
  "sxp-sxp",
  "rvn-rvn",
  "fei-fei",
  "iost-iostoken",
  "zen-zen",
  "fxs-fxs",
  "ttt-ttt",
  "sc-siacoin",
  "ever-ever",
  "chsb-chsb",
  "waxp-waxp",
  "husd-husd",
  "ont-ontology",
  "kok-kok",
  "vlx-vlx",
  "rndr-rndr",
  "uma-uma",
  "ens-ens",
  "imx-imx",
  "btrst-btrst",
  "glm-glm",
  "rly-rly",
  "woo-woo",
  "storj-storj",
  "vgx-vgx",
  "hive-hive",
  "renbtc-renbtc",
  "rpl-rpl",
  "ckb-ckb",
  "xwc-xwc",
  "raca-raca",
  "wemix-wemix",
  "ilv-ilv",
  "cspr-cspr",
  "poly-polymath",
  "sushi-sushi",
  "safemoon-safemoon",
  "mxc-mxc",
  "keep-keep",
  "dgb-digibyte",
  "best-best",
  "ren-ren",
  "spell-spell",
  "twt-twt",
  "mco-mco",
  "deso-deso",
  "bsw-bsw",
  "tel-tel",
  "arrr-arrr",
  "ceek-ceek",
  "ocean-ocean",
  "sys-syscoin",
  "bnx-bnx",
  "win-wincoin",
  "dydx-dydx",
  "dar-darcrus",
  "celr-celr",
  "xprt-xprt",
  "xdb-dragonsphere",
  "hum-hum",
  "lsk-lisk",
  "cbg-cbg",
  "dao-dao",
  "inj-inj",
  "cfx-cfx",
  "ray-ray",
  "nu-nu",
  "xch-clearinghouse",
  "dag-dag",
  "c98-c98",
  "coti-coti",
  "dent-dent",
  "med-mediterraneancoin",
  "gusd-gusd",
  "mob-mobcoin",
  "pyr-pyr",
  "any-any",
  "fet-fet",
  "powr-power-ledger",
  "mpl-maples",
  "mvl-mvl",
  "uos-uos",
  "pundix-pundix",
  "chr-chr",
  "erowan-erowan",
  "wrx-wrx",
  "joe-joe",
  "perp-perp",
  "people-people",
  "vbtc-vbtc",
  "tlos-tlos",
  "agi-singularitynet",
  "xyo-xyo",
  "orbs-orbs",
  "mrph-mrph",
  "snt-status",
  "req-request-network",
  "divi-divi",
  "solo-solo",
  "aurora-aurora",
  "fx-fx",
  "usdd-usdd",
  "ardr-ardor",
  "tribe-tribe",
  "btcst-btcst",
  "ogn-origintoken",
  "steem-steem",
  "xsgd-xsgd",
  "ctsi-cartesi",
  "1eco-1eco",
  "lyxe-lyxe",
  "mbox-mbox",
  "alice-alice",
  "movr-movr",
  "hedg-hedg",
  "mdx-mdx",
  "ygg-ygg",
  "stmx-stormx",
  "metis-metis",
  "eps-eps",
  "veth-veth",
  "orc-orc",
  "vtho-vethor",
  "dero-dero",
  "ctc-ctc",
  "trac-trac",
  "oxt-oxt",
  "sun-suncoin",
  "eurs-eurs",
  "hxro-hxro",
  "rsr-rsr",
  "elf-aelf",
  "xvg-verge",
  "nmr-nmr",
  "pro-payprocoin",
  "reef-reef",
  "ant-ant",
  "bfc-bfc",
  "bico-bico",
  "rep-augur",
  "ewt-ewt",
  "boba-boba",
  "rgt-rgt",
  "maid-maidsafecoin",
  "ufo-ufo-coin",
  "mtl-metal",
  "asd-asd",
  "api3-api3",
  "wxt-wxt",
  "rlc-rlc",
  "prom-prometeus",
  "alpha-alpha",
  "strax-strax",
  "susd-susd",
  "fun-funfair",
  "dawn-dawn",
  "lqty-lqty",
  "nkn-nkn",
  "stpt-standard-tokenization-protocol",
  "rad-rad",
  "ghst-ghst",
  "hoo-hoo",
  "ads-ads",
  "ssx-ssx",
  "ark-ark",
  "rbtc-rsk-smart-bitcoin",
  "mbl-mbl",
  "cennz-cennz",
  "ssv-ssvcoin",
  "rif-rif",
  "bake-bake",
  "band-band",
  "qkc-qkc",
  "akt-akt",
  "mngo-mngo",
  "starl-starl"
]

exports.exchangeMarkets = [
  {
    exchName: "lightspeed-crypto",
    marketsArray: [
        "BTC/USD",
        "ETH/USD",
        "LTC/USD",
        "BCH/USD"
    ]
  },
  {
    exchName: "kucn",
    marketsArray: [
        "ETH/BTC",
        "BTC/USDT",
        "XRP/BTC",
        "LTC/BTC",
        "DASH/BTC",
        "NEO/BTC",
        "ETH/USDT",
        "DOGE/BTC",
        "ADA/BTC",
        "OMG/BTC",
        "ETC/BTC",
        "XLM/BTC",
        "ZEC/BTC",
        "LTC/USDT",
        "LSK/BTC",
        "XEM/BTC",
        "TRX/BTC",
        "XMR/BTC",
        "NEO/USDT",
        "XRP/USDT",
        "EOS/BTC",
        "QTUM/BTC",
        "WAVES/BTC",
        "DGB/BTC",
        "IOTA/BTC",
        "POWR/BTC",
        "CVC/BTC",
        "DASH/USDT",
        "PIVX/BTC",
        "KMD/BTC",
        "ETC/USDT",
        "NEO/ETH",
        "BCH/BTC",
        "DCR/BTC",
        "ZEC/USDT",
        "ZRX/BTC",
        "BNB/BTC",
        "XRP/ETH",
        "MTL/BTC",
        "SNT/BTC",
        "STORJ/BTC",
        "SYS/BTC",
        "OMG/ETH",
        "EOS/ETH",
        "OMG/USDT",
        "LTC/ETH",
        "ETC/ETH",
        "MANA/BTC",
        "EDG/BTC",
        "ANT/BTC"
    ]
  },
  {
    exchName: "binance-futures",
    marketsArray: [
        "BTC/USDT",
        "ETH/USDT",
        "LTC/USDT",
        "NEO/USDT",
        "XRP/USDT",
        "DASH/USDT",
        "ETC/USDT",
        "ZEC/USDT",
        "OMG/USDT",
        "ADA/USDT",
        "XMR/USDT",
        "BNB/USDT",
        "BCH/USDT",
        "QTUM/USDT",
        "TRX/USDT",
        "SC/USDT",
        "EOS/USDT",
        "1INCH/USDT",
        "ATOM/USDT",
        "BAT/USDT",
        "IOST/USDT",
        "IOTA/USDT",
        "LINK/USDT",
        "ONT/USDT",
        "VET/USDT",
        "XLM/USDT",
        "XTZ/USDT",
        "THETA/USDT",
        "ALGO/USDT",
        "ZIL/USDT",
        "KNC/USDT",
        "ZRX/USDT",
        "COMP/USDT",
        "DOGE/USDT",
        "SXP/USDT",
        "KAVA/USDT",
        "BAND/USDT",
        "RLC/USDT",
        "WAVES/USDT",
        "SNX/USDT",
        "MKR/USDT",
        "DOT/USDT",
        "DEFI/USDT",
        "YFI/USDT",
        "BAL/USDT",
        "CRV/USDT",
        "TRB/USDT",
        "RUNE/USDT",
        "SUSHI/USDT",
        "SRM/USDT"
    ]
  },
  {
    exchName: "ftx-us",
    marketsArray: [
        "ETH/BTC",
        "BTC/USDT",
        "LTC/BTC",
        "BTC/USD",
        "ETH/USD",
        "ETH/USDT",
        "DOGE/BTC",
        "LTC/USD",
        "LTC/USDT",
        "BTC/EUR",
        "ETH/EUR",
        "BCH/USD",
        "BCH/BTC",
        "LINK/BTC",
        "BCH/USDT",
        "USDT/USD",
        "TRX/USD",
        "BTC/AUD",
        "ETH/AUD",
        "EUR/USD",
        "DOGE/USD",
        "BAT/USD",
        "TRX/USDT",
        "DAI/USD",
        "MATIC/USD",
        "SOL/USD",
        "ALGO/USDT",
        "ALGO/USD",
        "NEAR/USDT",
        "NEAR/USD",
        "AVAX/USDT",
        "AVAX/USD",
        "AVAX/BTC",
        "SHIB/USD",
        "KSHIB/USD",
        "AAVE/USDT",
        "MKR/USD",
        "MATIC/BTC",
        "BTC/BRZ",
        "BRZ/USDT",
        "BRZ/USD",
        "AAVE/USD",
        "GBP/USD",
        "CAD/USD",
        "GRT/USD",
        "DAI/USDT",
        "AUD/USD",
        "WBTC/USD",
        "DOGE/USDT",
        "YFI/USDT"
    ]
  },
  {
    exchName: "ftx-international",
    marketsArray: [
        "ETH/BTC",
        "BTC/USDT",
        "XRP/BTC",
        "LTC/BTC",
        "BTC/USD",
        "ETH/USD",
        "ETH/USDT",
        "DOGE/BTC",
        "LTC/USD",
        "LTC/USDT",
        "TRX/BTC",
        "BTC/EUR",
        "XRP/USDT",
        "XRP/USD",
        "ETH/EUR",
        "BCH/USD",
        "BCH/BTC",
        "BNB/BTC",
        "OMG/USD",
        "BNB/USDT",
        "LINK/BTC",
        "BCH/USDT",
        "USDT/USD",
        "TRX/USD",
        "ZRX/USD",
        "BTC/JPY",
        "EUR/USD",
        "DOGE/USD",
        "MANA/USD",
        "CVC/USD",
        "BAT/USD",
        "ETH/JPY",
        "TRX/USDT",
        "BNT/USD",
        "ENJ/USD",
        "XRP/JPY",
        "DAI/USD",
        "LRC/USD",
        "DOT/USD",
        "FTM/USD",
        "MATIC/USD",
        "SOL/USD",
        "BTC-MOVE-0510/USD",
        "BTC-MOVE-0509/USD",
        "BTC-MOVE-WK-0603/USD",
        "BTC-MOVE-0508/USD",
        "BTC-MOVE-0507/USD",
        "GAL-PERP/USD",
        "BTC-MOVE-0506/USD",
        "GALFAN/USD"
    ]
  },
  {
    exchName: "kraken",
    marketsArray: [
        "ETH/XBT",
        "XBT/USDT",
        "XRP/XBT",
        "LTC/XBT",
        "XBT/USD",
        "DASH/XBT",
        "ETH/USD",
        "ETH/USDT",
        "XDG/XBT",
        "ADA/XBT",
        "OMG/XBT",
        "ETC/XBT",
        "LTC/USD",
        "XLM/XBT",
        "ZEC/XBT",
        "LTC/USDT",
        "LSK/XBT",
        "SC/XBT",
        "TRX/XBT",
        "XBT/EUR",
        "XMR/XBT",
        "XRP/USDT",
        "EOS/XBT",
        "QTUM/XBT",
        "XRP/USD",
        "WAVES/XBT",
        "BAT/XBT",
        "ETH/EUR",
        "ICX/XBT",
        "BCH/USD",
        "BCH/XBT",
        "ZRX/XBT",
        "LTC/EUR",
        "XRP/ETH",
        "XMR/USD",
        "ETC/USD",
        "STORJ/XBT",
        "EOS/USD",
        "ADA/ETH",
        "OMG/ETH",
        "EOS/ETH",
        "ZEC/USD",
        "XRP/EUR",
        "LTC/ETH",
        "ETC/ETH",
        "MANA/XBT",
        "ANT/XBT",
        "TRX/ETH",
        "DASH/USD",
        "OMG/USD"
    ]
  },
  {
    exchName: "bitstamp",
    marketsArray: [
        "BTC/ETH",
        "USDT/BTC",
        "BTC/XRP",
        "BTC/LTC",
        "USD/BTC",
        "USD/ETH",
        "BTC/ADA",
        "BTC/OMG",
        "USD/LTC",
        "BTC/XLM",
        "EUR/BTC",
        "USD/XRP",
        "BTC/BAT",
        "EUR/ETH",
        "USD/BCH",
        "BTC/BCH",
        "BTC/ZRX",
        "EUR/LTC",
        "EUR/XRP",
        "USD/OMG",
        "EUR/BCH",
        "BTC/LINK",
        "BTC/KNC",
        "GBP/BTC",
        "USD/XLM",
        "EUR/XLM",
        "ETH/LINK",
        "USD/ZRX",
        "USD/EUR",
        "GBP/ETH",
        "USD/BAT",
        "USD/ADA",
        "GBP/BCH",
        "USD/DAI",
        "USD/ANT",
        "USDC/ETH",
        "EUR/ADA",
        "USD/FTM",
        "EUR/GBP",
        "USD/GBP",
        "GBP/LTC",
        "GBP/XRP",
        "EUR/PAX",
        "GBP/PAX",
        "PAX/BTC",
        "PAX/ETH",
        "PAX/XRP",
        "USD/PAX",
        "GBP/XLM",
        "USD/LINK"
    ]
  },
  {
    exchName: "bittrex",
    marketsArray: [
        "ETH/BTC",
        "BTC/USDT",
        "XRP/BTC",
        "LTC/BTC",
        "BTC/USD",
        "DASH/BTC",
        "ETH/USD",
        "NEO/BTC",
        "ETH/USDT",
        "DOGE/BTC",
        "ADA/BTC",
        "OMG/BTC",
        "ETC/BTC",
        "LTC/USD",
        "XVG/BTC",
        "ZEC/BTC",
        "LTC/USDT",
        "LSK/BTC",
        "SC/BTC",
        "XEM/BTC",
        "TRX/BTC",
        "STEEM/BTC",
        "BTC/EUR",
        "NEO/USDT",
        "XRP/USDT",
        "EOS/BTC",
        "QTUM/BTC",
        "ARK/BTC",
        "XRP/USD",
        "WAVES/BTC",
        "NXT/BTC",
        "BAT/BTC",
        "DGB/BTC",
        "PAY/BTC",
        "IOTA/BTC",
        "POWR/BTC",
        "ETH/EUR",
        "BTS/BTC",
        "CVC/BTC",
        "DASH/USDT",
        "ICX/BTC",
        "ADX/BTC",
        "PIVX/BTC",
        "KMD/BTC",
        "VTC/BTC",
        "ETC/USDT",
        "BCH/USD",
        "NEO/ETH",
        "ARDR/BTC",
        "BCH/BTC"
    ]
  },
  {
    exchName: "poloniex",
    marketsArray: [
        "BTC/ETH",
        "USDT/BTC",
        "BTC/XRP",
        "BTC/LTC",
        "BTC/DASH",
        "BTC/NEO",
        "USDT/ETH",
        "BTC/DOGE",
        "BTC/ADA",
        "BTC/OMG",
        "BTC/ETC",
        "BTC/ZEC",
        "USDT/LTC",
        "BTC/LSK",
        "BTC/SC",
        "BTC/XEM",
        "BTC/TRX",
        "BTC/STEEM",
        "BTC/XMR",
        "USDT/NEO",
        "USDT/XRP",
        "BTC/EOS",
        "BTC/QTUM",
        "BTC/NXT",
        "BTC/BAT",
        "BTC/BTS",
        "BTC/CVC",
        "USDT/DASH",
        "USDT/ETC",
        "BTC/ARDR",
        "BTC/BCH",
        "BTC/DCR",
        "USDT/ZEC",
        "BTC/ZRX",
        "BTC/AMP",
        "BTC/SNT",
        "BTC/STORJ",
        "BTC/STR",
        "ETH/EOS",
        "ETH/ETC",
        "BTC/MANA",
        "BTC/GAS",
        "BTC/BNT",
        "USDT/ADA",
        "USDT/XMR",
        "USDT/BNB",
        "BTC/LINK",
        "USDT/STR",
        "ETH/ZEC",
        "USDT/BCH"
    ]
  },
  {
    exchName: "bitfinex",
    marketsArray: [
        "ETH/BTC",
        "XRP/BTC",
        "LTC/BTC",
        "BTC/USD",
        "ETH/USD",
        "NEO/BTC",
        "DOGE/BTC",
        "ADA/BTC",
        "OMG/BTC",
        "ETC/BTC",
        "LTC/USD",
        "XLM/BTC",
        "ZEC/BTC",
        "TRX/BTC",
        "BTC/EUR",
        "XMR/BTC",
        "EOS/BTC",
        "XRP/USD",
        "ETH/EUR",
        "NEO/ETH",
        "ZRX/BTC",
        "IOT/USD",
        "XMR/USD",
        "ETC/USD",
        "EOS/USD",
        "OMG/ETH",
        "EOS/ETH",
        "ZEC/USD",
        "IOT/BTC",
        "ANT/BTC",
        "XLM/ETH",
        "NEO/USD",
        "TRX/ETH",
        "OMG/USD",
        "BTG/BTC",
        "DSH/USD",
        "KNC/BTC",
        "BTG/USD",
        "ZRX/ETH",
        "DSH/BTC",
        "IOT/ETH",
        "TRX/USD",
        "ETP/USD",
        "ZIL/BTC",
        "SAN/USD",
        "BTC/GBP",
        "QTM/USD",
        "ETP/BTC",
        "XLM/USD",
        "REP/BTC"
    ]
  },
  {
    exchName: "binance",
    marketsArray: [
        "ETH/BTC",
        "BTC/USDT",
        "XRP/BTC",
        "LTC/BTC",
        "DASH/BTC",
        "NEO/BTC",
        "ETH/USDT",
        "DOGE/BTC",
        "ADA/BTC",
        "OMG/BTC",
        "ETC/BTC",
        "XLM/BTC",
        "XVG/BTC",
        "ZEC/BTC",
        "LTC/USDT",
        "LSK/BTC",
        "SC/BTC",
        "XEM/BTC",
        "TRX/BTC",
        "STEEM/BTC",
        "BTC/EUR",
        "XMR/BTC",
        "NEO/USDT",
        "XRP/USDT",
        "EOS/BTC",
        "QTUM/BTC",
        "ARK/BTC",
        "WAVES/BTC",
        "BAT/BTC",
        "DGB/BTC",
        "IOTA/BTC",
        "POWR/BTC",
        "ETH/EUR",
        "BTS/BTC",
        "CVC/BTC",
        "DASH/USDT",
        "ICX/BTC",
        "ADX/BTC",
        "PIVX/BTC",
        "KMD/BTC",
        "ETC/USDT",
        "NEO/ETH",
        "ARDR/BTC",
        "VEN/BTC",
        "BCH/BTC",
        "DCR/BTC",
        "ZEC/USDT",
        "ZRX/BTC",
        "LTC/EUR",
        "BNB/BTC"
    ]
  },
  {
    exchName: "coinbase-pro",
    marketsArray: [
        "ETH/BTC",
        "BTC/USDT",
        "LTC/BTC",
        "BTC/USD",
        "DASH/BTC",
        "ETH/USD",
        "ETH/USDT",
        "DOGE/BTC",
        "ADA/BTC",
        "OMG/BTC",
        "ETC/BTC",
        "LTC/USD",
        "XLM/BTC",
        "ZEC/BTC",
        "BTC/EUR",
        "EOS/BTC",
        "BAT/BTC",
        "ETH/EUR",
        "BCH/USD",
        "BCH/BTC",
        "ZRX/BTC",
        "LTC/EUR",
        "ETC/USD",
        "STORJ/BTC",
        "EOS/USD",
        "ADA/ETH",
        "ZEC/USD",
        "MANA/BTC",
        "ZEN/BTC",
        "DASH/USD",
        "BNT/BTC",
        "OMG/USD",
        "ADA/USDT",
        "BCH/EUR",
        "REQ/BTC",
        "LINK/BTC",
        "RLC/BTC",
        "ENJ/BTC",
        "LRC/BTC",
        "KNC/BTC",
        "NMR/BTC",
        "ETC/EUR",
        "BAT/ETH",
        "USDT/USD",
        "BTC/GBP",
        "XLM/USD",
        "REP/BTC",
        "MANA/ETH",
        "EOS/EUR",
        "XLM/EUR"
    ]
  },
  {
    exchName: "gemini",
    marketsArray: [
        "ETH/BTC",
        "LTC/BTC",
        "BTC/USD",
        "ETH/USD",
        "DOGE/BTC",
        "LTC/USD",
        "ZEC/BTC",
        "BTC/EUR",
        "BAT/BTC",
        "ETH/EUR",
        "BCH/USD",
        "BCH/BTC",
        "ZEC/USD",
        "LTC/ETH",
        "LINK/BTC",
        "ZEC/ETH",
        "BAT/ETH",
        "BTC/GBP",
        "LINK/ETH",
        "ZRX/USD",
        "ETH/GBP",
        "DOGE/USD",
        "MANA/USD",
        "CVC/USD",
        "XTZ/USD",
        "BAT/USD",
        "BCH/ETH",
        "ENJ/USD",
        "BNT/USD",
        "DOGE/ETH",
        "DAI/USD",
        "LRC/USD",
        "MATIC/USD",
        "FTM/USD",
        "SOL/USD",
        "ZEC/LTC",
        "LTC/BCH",
        "ZEC/BCH",
        "OXT/USD",
        "OXT/ETH",
        "OXT/BTC",
        "BTC/DAI",
        "ETH/DAI",
        "LINK/USD",
        "BAL/USD",
        "PAXG/USD",
        "COMP/USD",
        "CRV/USD",
        "AMP/USD",
        "SNX/USD"
    ]
  },
  {
    exchName: "vaultoro",
    marketsArray: [
        "BTC/DASH",
        "BTC/GOLD",
        "DASH/GOLD",
        "ETH/SILV",
        "ETH/GOLD",
        "DASH/SILV",
        "BTC/SILV"
    ]
  },
  {
    exchName: "gateio",
    marketsArray: [
        "ETH/BTC",
        "BTC/USDT",
        "XRP/BTC",
        "LTC/BTC",
        "BTC/USD",
        "DASH/BTC",
        "ETH/USD",
        "NEO/BTC",
        "ETH/USDT",
        "DOGE/BTC",
        "ADA/BTC",
        "OMG/BTC",
        "ETC/BTC",
        "LTC/USD",
        "XLM/BTC",
        "XVG/BTC",
        "ZEC/BTC",
        "LTC/USDT",
        "LSK/BTC",
        "XEM/BTC",
        "XMR/BTC",
        "NEO/USDT",
        "XRP/USDT",
        "EOS/BTC",
        "QTUM/BTC",
        "XRP/USD",
        "WAVES/BTC",
        "BAT/BTC",
        "PAY/BTC",
        "IOTA/BTC",
        "POWR/BTC",
        "BTS/BTC",
        "DASH/USDT",
        "ETC/USDT",
        "BCH/USD",
        "BCH/BTC",
        "DCR/BTC",
        "ZEC/USDT",
        "ZRX/BTC",
        "BNB/BTC",
        "SNT/BTC",
        "STORJ/BTC",
        "OMG/ETH",
        "EOS/ETH",
        "OMG/USDT",
        "ETC/ETH",
        "XLM/ETH",
        "GAS/BTC",
        "TRX/ETH",
        "OMG/USD"
    ]
  },
  {
    exchName: "hitbtc",
    marketsArray: [
        "ETH/BTC",
        "XRP/BTC",
        "LTC/BTC",
        "BTC/USD",
        "DASH/BTC",
        "ETH/USD",
        "NEO/BTC",
        "DOGE/BTC",
        "ADA/BTC",
        "OMG/BTC",
        "ETC/BTC",
        "LTC/USD",
        "XLM/BTC",
        "XVG/BTC",
        "ZEC/BTC",
        "LSK/BTC",
        "SC/BTC",
        "XEM/BTC",
        "TRX/BTC",
        "STEEM/BTC",
        "XMR/BTC",
        "XRP/USDT",
        "EOS/BTC",
        "QTUM/BTC",
        "ARK/BTC",
        "WAVES/BTC",
        "NXT/BTC",
        "BAT/BTC",
        "DGB/BTC",
        "PAY/BTC",
        "IOTA/BTC",
        "POWR/BTC",
        "BTS/BTC",
        "CVC/BTC",
        "ICX/BTC",
        "KMD/BTC",
        "VTC/BTC",
        "BCH/USD",
        "NEO/ETH",
        "ARDR/BTC",
        "BCH/BTC",
        "DCR/BTC",
        "ZRX/BTC",
        "BNB/BTC",
        "XRP/ETH",
        "NAV/BTC",
        "XMR/USD",
        "AMP/BTC",
        "SNT/BTC",
        "ETC/USD"
    ]
  },
  {
    exchName: "hubi",
    marketsArray: [
        "ETH/BTC",
        "BTC/USDT",
        "XRP/BTC",
        "LTC/BTC",
        "DASH/BTC",
        "NEO/BTC",
        "ETH/USDT",
        "DOGE/BTC",
        "ADA/BTC",
        "OMG/BTC",
        "ETC/BTC",
        "XLM/BTC",
        "XVG/BTC",
        "ZEC/BTC",
        "LTC/USDT",
        "LSK/BTC",
        "SC/BTC",
        "XEM/BTC",
        "TRX/BTC",
        "STEEM/BTC",
        "XMR/BTC",
        "NEO/USDT",
        "XRP/USDT",
        "EOS/BTC",
        "QTUM/BTC",
        "WAVES/BTC",
        "BAT/BTC",
        "DGB/BTC",
        "PAY/BTC",
        "IOTA/BTC",
        "POWR/BTC",
        "BTS/BTC",
        "CVC/BTC",
        "DASH/USDT",
        "ICX/BTC",
        "ADX/BTC",
        "KMD/BTC",
        "ETC/USDT",
        "ARDR/BTC",
        "BCH/BTC",
        "DCR/BTC",
        "ZEC/USDT",
        "ZRX/BTC",
        "MTL/BTC",
        "SNT/BTC",
        "STORJ/BTC",
        "ADA/ETH",
        "OMG/ETH",
        "EOS/ETH",
        "OMG/USDT"
    ]
  }
];