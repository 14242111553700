import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from './storage'
import ReduxThunk from 'redux-thunk'
import exchDataReducer from '../store/reducers/exchDataReducer'
import newsReducer from '../store/reducers/newsReducer'
import marketsReducer from '../store/reducers/marketsReducer'
import currenciesReducer from '../store/reducers/currenciesReducer'
import userReducer from '../store/reducers/userReducer'

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['user', 'exchData', 'news', 'markets', 'currencies'],
}

const userPersistConfig = {
  key: 'user',
  storage: storage,
  whitelist: [
    'darkMode',
    'selectedCurr',
    'currSign',
    'highChartsRange',
    'tableRows',
  ],
}

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  exchData: exchDataReducer,
  news: newsReducer,
  markets: marketsReducer,
  currencies: currenciesReducer,
})

// added redux-devtools-extension (development only)
const composeEnhancers =
  process.env.NODE_ENV === `development` && typeof window !== 'undefined'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)
// const preloadedState = typeof window !== 'undefined' ? window.__PRELOADED_STATE__ : {};

export default () => {
  const store = createStore(
    persistedReducer,
    {}, // initial state
    composeEnhancers(applyMiddleware(ReduxThunk))
  )
  const persistor = persistStore(store)
  return { store, persistor }
}
