import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import storeHelper from './StoreHelper'

const persistor = storeHelper().persistor
const store = storeHelper().store

const ReduxWrapper = ({ children }) => {
  const { bootstrapped } = persistor.getState()

  if (bootstrapped === true) {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {children}
        </PersistGate>
      </Provider>
    )
  } else return <Provider store={store}>{children}</Provider>
}

export default ReduxWrapper
