import React from 'react'

const SvgMode = ({ isDarkMode, width, height, Dark, Light }) =>
  isDarkMode ? (
    <Dark {...{ width }} {...{ height }} />
  ) : (
    <Light {...{ width }} {...{ height }} />
  )

export default SvgMode
