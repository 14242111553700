class ExchMarketData {
  constructor(
    exchmktId,
    displayName,
    exchId,
    exchName,
    exchCode,
    marketId,
    marketName,
    baseCurrencyCode,
    quoteCurrencyCode,
    baseIsFiat,
    quoteIsFiat,
    baseCurrencyName,
    quoteCurrencyName,
    quantityPrecision,
    pricePrecision,
    last,
    volume24,
    candles,
    prevMarketName,
    prevDisplayName,
    nextMarketName,
    nextDisplayName,
    nextSlug,
    prevSlug,
    nextSlugName,
    prevSlugName
  ) {
    this.exchmktId = typeof exchmktId === 'undefined' ? '' : exchmktId
    this.displayName = typeof displayName === 'undefined' ? '' : displayName
    this.exchId = typeof exchId === 'undefined' ? '' : exchId
    this.exchName = typeof exchName === 'undefined' ? '' : exchName
    this.exchCode = typeof exchCode === 'undefined' ? '' : exchCode
    this.marketId = typeof marketId === 'undefined' ? '' : marketId
    this.marketName = typeof marketName === 'undefined' ? '' : marketName
    this.baseCurrencyCode =
      typeof baseCurrencyCode === 'undefined' ? '' : baseCurrencyCode
    this.quoteCurrencyCode =
      typeof quoteCurrencyCode === 'undefined' ? '' : quoteCurrencyCode
    this.baseIsFiat = typeof baseIsFiat === 'undefined' ? '' : baseIsFiat
    this.quoteIsFiat = typeof quoteIsFiat === 'undefined' ? '' : quoteIsFiat
    this.baseCurrencyName =
      typeof baseCurrencyName === 'undefined' ? '' : baseCurrencyName
    this.quoteCurrencyName =
      typeof quoteCurrencyName === 'undefined' ? '' : quoteCurrencyName
    this.quantityPrecision =
      typeof quantityPrecision === 'undefined' ? '' : quantityPrecision
    this.pricePrecision =
      typeof pricePrecision === 'undefined' ? '' : pricePrecision
    this.last = typeof last === 'undefined' ? '' : last
    this.volume24 = typeof volume24 === 'undefined' ? '' : volume24
    this.candles = typeof candles === 'undefined' ? '' : candles
    this.prevMarketName =
      typeof prevMarketName === 'undefined' ? '' : prevMarketName
    this.prevDisplayName =
      typeof prevDisplayName === 'undefined' ? '' : prevDisplayName
    this.nextMarketName =
      typeof nextMarketName === 'undefined' ? '' : nextMarketName
    this.nextDisplayName =
      typeof nextDisplayName === 'undefined' ? '' : nextDisplayName
    this.nextSlug = typeof nextSlug === 'undefined' ? '' : nextSlug
    this.prevSlug = typeof prevSlug === 'undefined' ? '' : prevSlug
    this.nextSlugName = typeof nextSlugName === 'undefined' ? '' : nextSlugName
    this.prevSlugName = typeof prevSlugName === 'undefined' ? '' : prevSlugName
  }
}

export default ExchMarketData
