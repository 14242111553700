import React from 'react'
import styled from 'styled-components'
import { color, space, typography, shadow } from 'styled-system'
import { device } from '../../utils'

const SectionTitle = styled.h2`
  font-weight: 500;
  letter-spacing: -2px;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 16px;

  @media ${device.sm} {
    font-size: 28px;
    line-height: 33px;
  }

  @media ${device.lg} {
    font-size: 40px;
    line-height: 46px;
    margin-bottom: 30px;
  }

  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const HighlightTitleHome = styled.h2`
  font-weight: 500;
  letter-spacing: -2px;
  font-size: 40px;
  line-height: 54px;
  margin-bottom: 16px;

  @media ${device.sm} {
    font-size: 50px;
    line-height: 62px;
  }

  @media ${device.lg} {
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 30px;
  }

  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const HeroTitle = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -0.01em;
  text-transform: uppercase;

  @media ${device.xs} {
    font-size: 28px;
    line-height: 33px;
  }

  @media ${device.sm} {
    font-size: 28px;
    line-height: 33px;
  }

  @media ${device.lg} {
    font-size: 40px;
    line-height: 46px;
  }

  @media ${device.xl} {
    font-size: 40px;
    line-height: 46px;
  }

  ${color};
`

const HeroTitleSmaller = styled(SectionTitle)`
  letter-spacing: -2px;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;

  @media ${device.xs} {
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.8px;
  }

  @media ${device.sm} {
    font-size: 26px;
    line-height: 30px;
    letter-spacing: -1.34px;
  }

  @media ${device.lg} {
    font-size: 34px;
    line-height: 50px;
  }

  @media ${device.xl} {
    font-size: 42px;
    line-height: 48px;
  }

  ${color};
`

const HeroTitleHome = styled.h1`
  font-weight: 600;
  letter-spacing: -3px;
  font-size: 50px;
  line-height: 56px;
  margin-bottom: 18px;

  @media ${device.md} {
    font-size: 66px;
    line-height: 70px;
    letter-spacing: -4px;
  }

  @media ${device.xl} {
    font-size: 80px;
    line-height: 80px;
    letter-spacing: -4px;
  }

  ${color};
`

const CardTitle = styled.h4`
  font-size: 21px;
  font-weight: 450;
  letter-spacing: -0.2px;
  line-height: 28px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const Title = ({ variant, ...rest }) => {
  let TitleStyled = SectionTitle

  switch (variant) {
    case 'card':
      TitleStyled = CardTitle
      break
    case 'hero':
      TitleStyled = HeroTitle
      break
    case 'hero-smaller':
      TitleStyled = HeroTitleSmaller
      break
    case 'hero-home':
      TitleStyled = HeroTitleHome
      break
    case 'highlight':
      TitleStyled = HighlightTitleHome
      break
    default:
      TitleStyled = SectionTitle
  }

  return <TitleStyled color="heading" {...rest} />
}

export default Title
