import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'

import { device, breakpoints } from '../../utils'
import Box from './Box'
import Text from './Text'

const BoxStyled = styled(Box)`
  margin-bottom: 0;

  .svg-container {
    padding: 0;
  }

  @media (max-width: ${breakpoints.xl}px) {
    margin-bottom: 20px;
  }

  @media (max-width: ${breakpoints.lg}px) {
    display: flex;
    justify-content: align-start;
    align-items: center;
  }
`

const BoxItems = ({ children, title, textColor, bgColor, ...rest }) => {
  return (
    <Col lg="3" md="6" sm="12" xs="12" className="d-flex align-items-stretch">
      <BoxStyled
        className="flex-fill"
        border="1px solid"
        borderColor="border"
        bg={bgColor}
        borderRadius={10}
        py="25px"
        px="30px"
        mb={4}
        {...rest}
      >
        {children}

        <Text className="mt-0 mt-lg-3 ml-3 ml-lg-0" color={textColor}>
          {title}
        </Text>
      </BoxStyled>
    </Col>
  )
}

export default BoxItems
