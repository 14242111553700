class Currency {
  constructor(
    id,
    code,
    name,
    isFiat,
    description,
    isTrackable,
    walletFieldName,
    availableSupply,
    miningTotalCirculation,
    marketCap,
    btcVolume24Hour,
    btcVolume1Hour,
    btcPrice,
    prevSlug,
    nextSlug,
    prevSlugName,
    nextSlugName,
    urlWebsite
  ) {
    this.id = typeof id === 'undefined' ? '' : id
    this.code = typeof code === 'undefined' ? '' : code
    this.name = typeof name === 'undefined' ? '' : name
    this.isFiat = typeof isFiat === 'undefined' ? '' : isFiat
    this.description = typeof description === 'undefined' ? '' : description
    this.isTrackable = typeof isTrackable === 'undefined' ? '' : isTrackable
    this.walletFieldName =
      typeof walletFieldName === 'undefined' ? '' : walletFieldName
    this.availableSupply =
      typeof availableSupply === 'undefined' ? '' : availableSupply
    this.miningTotalCirculation =
      typeof miningTotalCirculation === 'undefined'
        ? ''
        : miningTotalCirculation
    this.marketCap = typeof marketCap === 'undefined' ? '' : marketCap
    this.btcVolume24Hour =
      typeof btcVolume24Hour === 'undefined' ? '' : btcVolume24Hour
    this.btcVolume1Hour =
      typeof btcVolume1Hour === 'undefined' ? '' : btcVolume1Hour
    this.btcPrice = typeof btcPrice === 'undefined' ? '' : btcPrice
    this.prevSlug = typeof prevSlug === 'undefined' ? '' : prevSlug
    this.nextSlug = typeof nextSlug === 'undefined' ? '' : nextSlug
    this.prevSlugName = typeof prevSlugName === 'undefined' ? '' : prevSlugName
    this.nextSlugName = typeof nextSlugName === 'undefined' ? '' : nextSlugName
    this.urlWebsite = typeof urlWebsite === 'undefined' ? '' : urlWebsite
  }
}

export default Currency
