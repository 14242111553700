import React from 'react'
import styled from 'styled-components'
import { color, space, typography, shadow } from 'styled-system'

const Paragraph = styled.p`
  margin-bottom: 0;
  font-size: 21px;
  font-weight: 300;
  letter-spacing: 0px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const ParagraphSmall = styled(Paragraph)`
  font-size: 16px;
  letter-spacing: -0.1px;
  line-height: 24px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const ParagraphDisclaimer = styled(Paragraph)`
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 18px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const ParagraphStrong = styled(Paragraph)`
  font-weight: 700;
  letter-spacing: 0px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const ParagraphNumber = styled(Paragraph)`
  font-variant-numeric: tabular-nums;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const ParagraphLink = styled.p`
  font-size: 21px;
  font-weight: 300;
  letter-spacing: -0.2px;
  line-height: 25px;
  border-bottom: 2px solid;
  margin: 0 15px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
`

const Text = ({ variant, ...props }) => {
  let TextRender

  switch (variant) {
    case 'small':
      TextRender = ParagraphSmall
      break
    case 'strong':
      TextRender = ParagraphStrong
      break
    case 'number':
      TextRender = ParagraphNumber
      break
    case 'disclaimer':
      TextRender = ParagraphDisclaimer
      break
    case 'link':
      TextRender = ParagraphLink
      break
    default:
      TextRender = Paragraph
  }

  return <TextRender color="text" {...props} />
}

export default Text
