class SearchMarket {
  constructor(displayName, marketName, exchCode, exchName, exchSlug) {
    this.displayName = typeof displayName === 'undefined' ? '' : displayName
    this.marketName = typeof marketName === 'undefined' ? '' : marketName
    this.exchCode = typeof exchCode === 'undefined' ? '' : exchCode
    this.exchName = typeof exchName === 'undefined' ? '' : exchName
    this.exchSlug = typeof exchSlug === 'undefined' ? '' : exchSlug
  }
}

export default SearchMarket
