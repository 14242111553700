class ExchData {
  constructor(
    exchId,
    exchName,
    exchUrl,
    exchCode,
    heatIndex,
    exchNumCurrencies,
    exchNumMarkets,
    exchVolume24,
    exchVolume1,
    exchBalanceEnabledV2,
    exchTradeEnabledV2,
    hasSpotTrading,
    hasFuturesTrading,
    exchHasWhitelisting,
    slug,
    trafficRank,
    trafficRank90dChange
  ) {
    this.exchId = typeof exchId === 'undefined' ? '' : exchId
    this.exchName = typeof exchName === 'undefined' ? '' : exchName
    this.exchUrl = typeof exchUrl === 'undefined' ? '' : exchUrl
    this.exchCode = typeof exchCode === 'undefined' ? '' : exchCode
    this.heatIndex = typeof heatIndex === 'undefined' ? '' : heatIndex
    this.exchNumCurrencies =
      typeof exchNumCurrencies === 'undefined' ? '' : exchNumCurrencies
    this.exchNumMarkets =
      typeof exchNumMarkets === 'undefined' ? '' : exchNumMarkets
    this.exchVolume24 = typeof exchVolume24 === 'undefined' ? '' : exchVolume24
    this.exchVolume1 = typeof exchVolume1 === 'undefined' ? '' : exchVolume1
    this.exchBalanceEnabledV2 =
      typeof exchBalanceEnabledV2 === 'undefined' ? '' : exchBalanceEnabledV2
    this.exchTradeEnabledV2 =
      typeof exchTradeEnabledV2 === 'undefined' ? '' : exchTradeEnabledV2
    this.hasSpotTrading =
      typeof hasSpotTrading === 'undefined' ? '' : hasSpotTrading
    this.hasFuturesTrading =
      typeof hasFuturesTrading === 'undefined' ? '' : hasFuturesTrading
    this.exchHasWhitelisting =
      typeof exchHasWhitelisting === 'undefined' ? '' : exchHasWhitelisting
    this.slug = typeof slug === 'undefined' ? '' : slug
    this.trafficRank = typeof trafficRank === 'undefined' ? '' : trafficRank
    this.trafficRank90dChange =
      typeof trafficRank90dChange === 'undefined' ? '' : trafficRank90dChange
  }
}

export default ExchData
