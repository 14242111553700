import React, { useState, useEffect, useContext, useRef } from 'react'
import loadable from '@loadable/component'
import styled, { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet-async'
import AOS from 'aos'
import ComingSoon from '../../pages/coming-soon'
import useScript from '../../utils/hooks/useScript'

import { withTrans } from '../../helpers/WithTrans'

import GlobalContext from '../../context/GlobalContext'

import GlobalStyle from '../../utils/globalStyle'

import imgFavicon from '../../assets/favicon.ico'
import imgTouchIcon from '../../assets/apple-touch-icon.png'
import imgFavicon16 from '../../assets/favicon-16x16.png'
import imgFavicon32 from '../../assets/favicon-32x32.png'

import '../../assets/fonts/fontawesome-5/webfonts/fa-brands-400.ttf'
import '../../assets/fonts/fontawesome-5/webfonts/fa-regular-400.ttf'
import '../../assets/fonts/fontawesome-5/webfonts/fa-solid-900.ttf'
import '../../assets/fonts/typography-font/Poppins-Black.ttf'
import '../../assets/fonts/typography-font/Poppins-Bold.ttf'
import '../../assets/fonts/typography-font/Poppins-BoldItalic.ttf'
import '../../assets/fonts/typography-font/Poppins-ExtraBold.ttf'
import '../../assets/fonts/typography-font/Poppins-ExtraLight.ttf'
import '../../assets/fonts/typography-font/Poppins-ExtraLightItalic.ttf'
import '../../assets/fonts/typography-font/Poppins-Italic.ttf'
import '../../assets/fonts/typography-font/Poppins-Light.ttf'
import '../../assets/fonts/typography-font/Poppins-LightItalic.ttf'
import '../../assets/fonts/typography-font/Poppins-Medium.ttf'
import '../../assets/fonts/typography-font/Poppins-MediumItalic.ttf'
import '../../assets/fonts/typography-font/Poppins-Regular.ttf'
import '../../assets/fonts/typography-font/Poppins-SemiBold.ttf'
import '../../assets/fonts/typography-font/Poppins-SemiBoldItalic.ttf'
import '../../assets/fonts/typography-font/Poppins-Thin.ttf'
import '../../assets/fonts/typography-font/Poppins-ThinItalic.ttf'


import '../../assets/fonts/icon-font/fonts/avasta.ttf'
import '../../assets/fonts/icon-font/css/style.css'

import './bootstrap-custom.scss'
import '../../../node_modules/slick-carousel/slick/slick.css'
import '../../../node_modules/slick-carousel/slick/slick-theme.css'
import '../../../node_modules/aos/dist/aos.css'

import '../../assets/fonts/icon-font/css/style.css'
import '../../assets/fonts/typography-font/typo.css'
import '../../assets/fonts/fontawesome-5/css/all.css'

import { get, merge } from 'lodash'

// the full theme object
import { theme as baseTheme } from '../../utils'
import { isMaintMode, getParameterByName } from '../../utils/helperFn'

const Header = loadable(() => import('../Header'))
const Footer = loadable(() => import('../Footer'))

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out 0.5s;
  &.inActive {
    opacity: 0;
    visibility: hidden;
  }
`

// options for different color modes
const modes = { light: 'light', dark: 'dark' }
const maintMode = isMaintMode()

// merge the color mode with the base theme
// to create a new theme object
const getTheme = mode =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
  })

const Layout = ({ children, pageContext, location }) => {
  // const {i18next} = useI18next();
  const gContext = useContext(GlobalContext)

  const [visibleLoader, setVisibleLoader] = useState(true)

  useEffect(() => {
    AOS.init()
    setVisibleLoader(false)
  }, [])

  // Navbar style based on scroll
  const eleRef = useRef()
  useScript(maintMode || ['/black-friday/'].includes(pageContext.originalPath))

  useEffect(() => {
    window.addEventListener(
      'popstate',
      function(event) {
        // The popstate event is fired each time when the current history entry changes.
        //gContext.closeAbout();
        //gContext.closeContact();
        //gContext.closeOffcanvas();
      },
      { passive: true }
    )
    window.addEventListener(
      'pushState',
      function(event) {
        // The pushstate event is fired each time when the current history entry changes.
        //gContext.closeAbout();
        //gContext.closeContact();
        //gContext.closeOffcanvas();
      },
      { passive: true }
    )
  }, [gContext])

  useEffect(() => {
    if (
      window.location.hostname === 'services.coinigy.com' &&
      window.location.pathname !== '/en/services/'
    ) {
      window.location.replace('https://services.coinigy.com/en/services')
    }
  }, [window])

  if (maintMode && getParameterByName('bypass') !== 'true') {
    return (
      <>
        <ThemeProvider
          theme={
            gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
          }
        >
          <GlobalStyle />
          <Helmet>
            <title>
              Coinigy - Professional Bitcoin & Cryptocurrency Trading Platform
            </title>
            <link
              rel="icon"
              type="image/vnd.microsoft.icon"
              href={imgFavicon}
              sizes="any"
            />
            <link rel="apple-touch-icon" sizes="180x180" href={imgTouchIcon} />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href={imgFavicon16}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href={imgFavicon32}
            />
            <link
              rel="manifest"
              href={process.env.WWW_URL + `/assets/site.webmanifest`}
            />
            <meta
              name="msapplication-config"
              content={process.env.WWW_URL + `/assets/browserconfig.xml`}
            />
            <meta name="msapplication-TileColor" content="#1F1747" />
          </Helmet>
          <Loader id="loading" className={visibleLoader ? '' : 'inActive'}>
            <div className="load-circle">
              <span className="one"></span>
            </div>
          </Loader>
          <div className="site-wrapper overflow-hidden" ref={eleRef}>
            {visibleLoader ? (
              <div></div>
            ) : (
              <Header
                headerType="comingSoon"
                location={location}
                isDark={gContext.headerDark}
              />
            )}
            <ComingSoon />
            {visibleLoader ? (
              <div></div>
            ) : (
              <Footer footerType="comingSoon" isDark={gContext.footerDark} />
            )}
          </div>
        </ThemeProvider>
      </>
    )
  }

  if (pageContext.layout === 'bare') {
    return (
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <GlobalStyle />
        <Helmet>
          <title>
            Coinigy - Professional Bitcoin & Cryptocurrency Trading Platform
          </title>
          <link
            rel="icon"
            type="image/vnd.microsoft.icon"
            href={imgFavicon}
            sizes="any"
          />
          <link rel="apple-touch-icon" href={imgTouchIcon} />
          <link rel="icon" type="image/png" sizes="32x32" href={imgFavicon16} />
          <link rel="icon" type="image/png" sizes="16x16" href={imgFavicon32} />
          <link
            rel="manifest"
            href={process.env.WWW_URL + `/assets/site.webmanifest`}
          />
          <meta
            name="msapplication-config"
            content={process.env.WWW_URL + `/assets/browserconfig.xml`}
          />
          <meta name="msapplication-TileColor" content="#1F1747" />
        </Helmet>
        <Loader id="loading" className={visibleLoader ? '' : 'inActive'}>
          <div className="load-circle">
            <span className="one"></span>
          </div>
        </Loader>
        <div className="site-wrapper overflow-hidden" ref={eleRef}>
          {children}
        </div>
      </ThemeProvider>
    )
  }

  if (window.location.href.includes('services.coinigy.com')) {
    return (
      <>
        <ThemeProvider
          theme={
            gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
          }
        >
          <GlobalStyle />
          <Helmet>
            <title>
              Coinigy - Professional Bitcoin & Cryptocurrency Trading Platform
            </title>
            <link
              rel="icon"
              type="image/vnd.microsoft.icon"
              href={imgFavicon}
              sizes="any"
            />
            <link rel="apple-touch-icon" sizes="180x180" href={imgTouchIcon} />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href={imgFavicon16}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href={imgFavicon32}
            />
            <link
              rel="manifest"
              href={process.env.WWW_URL + `/assets/site.webmanifest`}
            />
            <meta
              name="msapplication-config"
              content={process.env.WWW_URL + `/assets/browserconfig.xml`}
            />
            <meta name="msapplication-TileColor" content="#1F1747" />
          </Helmet>
          <Loader id="loading" className={visibleLoader ? '' : 'inActive'}>
            <div className="load-circle">
              <span className="one"></span>
            </div>
          </Loader>
          <div className="site-wrapper overflow-hidden" ref={eleRef}>
            {visibleLoader ? (
              <div></div>
            ) : (
              <Header
                headerType="services"
                location={location}
                isDark={gContext.headerDark}
              />
            )}
            {children}
            {visibleLoader ? (
              <div></div>
            ) : (
              <Footer footerType="comingSoon" isDark={gContext.footerDark} />
            )}
          </div>
        </ThemeProvider>
      </>
    )
  }

  if (['/get-started/', '/black-friday/'].includes(pageContext.originalPath)) {
    return (
      <>
        <ThemeProvider
          theme={
            gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
          }
        >
          <GlobalStyle />
          <Helmet>
            <title>
              Coinigy - Professional Bitcoin & Cryptocurrency Trading Platform
            </title>
            <link
              rel="icon"
              type="image/vnd.microsoft.icon"
              href={imgFavicon}
              sizes="any"
            />
            <link rel="apple-touch-icon" href={imgTouchIcon} />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href={imgFavicon16}
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href={imgFavicon32}
            />
            <link
              rel="manifest"
              href={process.env.WWW_URL + `/assets/site.webmanifest`}
            />
            <meta
              name="msapplication-config"
              content={process.env.WWW_URL + `/assets/browserconfig.xml`}
            />
            <meta name="msapplication-TileColor" content="#1F1747" />
          </Helmet>
          <Loader id="loading" className={visibleLoader ? '' : 'inActive'}>
            <div className="load-circle">
              <span className="one"></span>
            </div>
          </Loader>
          <div className="site-wrapper overflow-hidden" ref={eleRef}>
            {visibleLoader ? (
              <div></div>
            ) : (
              <Header
                headerType="get-started"
                location={location}
                isDark={gContext.headerDark}
              />
            )}
            {children}
            {visibleLoader ? (
              <div></div>
            ) : (
              <Footer footerType="classic" isDark={gContext.footerDark} />
            )}
          </div>
        </ThemeProvider>
      </>
    )
  }

  return (
    <>
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <GlobalStyle />
        <Helmet>
          <title>
            Coinigy - Professional Bitcoin & Cryptocurrency Trading Platform
          </title>
          <link
            rel="icon"
            type="image/vnd.microsoft.icon"
            href={imgFavicon}
            sizes="any"
          />
          <link rel="apple-touch-icon" href={imgTouchIcon} />
          <link rel="icon" type="image/png" sizes="32x32" href={imgFavicon16} />
          <link rel="icon" type="image/png" sizes="16x16" href={imgFavicon32} />
          <link
            rel="manifest"
            href={process.env.WWW_URL + `/assets/site.webmanifest`}
          />
          <meta
            name="msapplication-config"
            content={process.env.WWW_URL + `/assets/browserconfig.xml`}
          />
          <meta name="msapplication-TileColor" content="#1F1747" />
        </Helmet>
        <Loader id="loading" className={visibleLoader ? '' : 'inActive'}>
          <div className="load-circle">
            <span className="one"></span>
          </div>
        </Loader>
        <div className="site-wrapper overflow-hidden" ref={eleRef}>
          {visibleLoader ? (
            <div></div>
          ) : (
            <Header location={location} isDark={gContext.headerDark} />
          )}
          {children}
          {visibleLoader ? (
            <div></div>
          ) : (
            <Footer footerType="classic" isDark={gContext.footerDark} />
          )}
        </div>
      </ThemeProvider>
    </>
  )
}

export default withTrans(Layout)
