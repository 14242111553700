import {
  SET_USER_CURR,
  CHECK_PHP_CONNECTION_STATUS,
  SET_DARK_MODE,
  GET_CSRF_TOKEN,
  GET_USER_SETTINGS,
  SET_HEADER_DARK,
  STORE_CSRF_COOKIE,
  STORE_NEW_TOKEN,
  SAVE_BROWSER_LANG,
  SET_GLOBAL_SEARCH,
  GET_FAV_MARKETS_IDS,
  SET_HIGH_CHARTS_RANGE,
  SAVE_FAV_MARKETS,
  DELETE_FAV_MARKETS,
  GET_AUTH_IDS,
  GET_AUTH_EXCH_IDS,
  GET_USER_BALANCES,
  GET_BALANCE_HISTORY,
  GET_USER_OPEN_ORDERS,
  GET_ORDER_TYPES,
  GET_ORDER_STATUS_TYPES,
  GET_ORDERS_HISTORY,
  GET_OHLC_PRICES,
  SET_TABLE_ROWS,
  GET_USER_PREFS,
  PLACE_ORDER,
  CHECK_USER_APPLICATIONS,
} from '../actions/userActions'

const initialState = {
  exchRate: 1,
  currSign: '$',
  selectedCurr: 'USD',
  // setting session_expired = true temporarily
  session_expired: true,
  darkMode: false,
  csrf_token: false,
  user_settings: {},
  favMarketIds: [],
  headerDark: true,
  csrfCookie: '',
  newToken: '',
  browserLang: '',
  searchResultExchs: [],
  searchResultMkts: [],
  searchResultCurrs: [],
  // searchResultNews: [],
  highChartsRange: null,
  authIds: [],
  authExchIds: [],
  balances: [],
  openOrders: [],
  orderTypes: [],
  orderStatusTypes: [],
  ordersHistory: [],
  balHistory: [],
  ohlcPrices: [],
  tableRows: 50,
  userPrefs: false,
  orderResponse: false,
  user_applications: [],
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_CURR:
      return {
        ...state,
        exchRate: action.exchRate,
        currSign: action.currSign,
        selectedCurr: action.selectedCurr,
      }
    case CHECK_PHP_CONNECTION_STATUS:
      return {
        ...state,
        session_expired: action.session_expired,
      }
    case SET_DARK_MODE:
      return {
        ...state,
        darkMode: action.darkMode,
      }
    case SAVE_BROWSER_LANG:
      return {
        ...state,
        browserLang: action.browserLang,
      }
    case SET_HEADER_DARK:
      return {
        ...state,
        headerDark: action.headerDark,
      }
    case GET_CSRF_TOKEN:
      return {
        ...state,
        csrf_token: action.csrf_token,
      }
    case STORE_CSRF_COOKIE:
      return {
        ...state,
        csrfCookie: action.csrfCookie,
      }
    case STORE_NEW_TOKEN:
      return {
        ...state,
        newToken: action.newToken,
      }
    case GET_USER_SETTINGS:
      return {
        ...state,
        user_settings: action.user_settings,
      }
    case GET_FAV_MARKETS_IDS:
      return {
        ...state,
        favMarketIds: action.favMarketIds,
      }
    case SAVE_FAV_MARKETS:
      return {
        ...state,
        favMarkets: action.favMarkets,
      }
    case DELETE_FAV_MARKETS:
      return {
        ...state,
        favMarkets: action.favMarkets,
      }
    case SET_GLOBAL_SEARCH:
      return {
        ...state,
        searchResultExchs: action.searchResultExchs,
        searchResultMkts: action.searchResultMkts,
        searchResultCurrs: action.searchResultCurrs,
        // searchResultNews: action.searchResultNews,
      }
    case SET_HIGH_CHARTS_RANGE:
      return {
        ...state,
        highChartsRange: action.highChartsRange,
      }
    case SET_TABLE_ROWS:
      return {
        ...state,
        tableRows: action.tableRows,
      }
    case GET_AUTH_IDS:
      return {
        ...state,
        authIds: action.authIds,
      }
    case GET_AUTH_EXCH_IDS:
      return {
        ...state,
        authExchIds: action.authExchIds,
      }
    case GET_USER_BALANCES:
      return {
        ...state,
        balances: action.balances,
      }
    case GET_BALANCE_HISTORY:
      return {
        ...state,
        balHistory: action.balHistory,
      }
    case GET_OHLC_PRICES:
      return {
        ...state,
        ohlcPrices: action.ohlcPrices,
      }
    case GET_USER_OPEN_ORDERS:
      return {
        ...state,
        openOrders: action.openOrders,
      }
    case GET_ORDER_TYPES:
      return {
        ...state,
        orderTypes: action.orderTypes,
      }
    case GET_ORDER_STATUS_TYPES:
      return {
        ...state,
        orderStatusTypes: action.orderStatusTypes,
      }
    case GET_ORDERS_HISTORY:
      return {
        ...state,
        ordersHistory: action.ordersHistory,
      }
    case GET_USER_PREFS:
      return {
        ...state,
        userPrefs: action.userPrefs,
      }
    case PLACE_ORDER:
      return {
        ...state,
        orderResponse: action.orderResponse,
      }
    case CHECK_USER_APPLICATIONS:
      return {
        ...state,
        user_applications: action.user_applications,
      }
    default:
      return state
  }
}

export default userReducer
