import React from 'react'
import MetaData from '../components/MetaData'
import { Container, Col } from 'react-bootstrap'
import PageWrapper from '../components/PageWrapper'
import { Section, Title, Text } from '../components/Core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import EmailForm from '../components/EmailForm/EmailForm.js'
import LogoDark from '../assets/image/svg/LsDarkLogo.inline.svg'
import LogoWhite from '../assets/image/svg/LsWhiteLogo.inline.svg'

const ComingSoon = props => {
  const { t, ready } = useTranslation(['comingSoon'], { useSuspense: false })
  const userDarkMode = useSelector(state => state.user.darkMode)

  return (
    ready && (
      <>
        <MetaData page="default" />
        <PageWrapper>
          <Section bg={userDarkMode ? 'black' : 'bg'}>
            <Container className="mt-5">
              {userDarkMode ? (
                <LogoWhite width="332" height="72" />
              ) : (
                <LogoDark width="332" height="72" />
              )}
              <Col className="mt-5" lg="12">
                <Title color={userDarkMode ? '#FAFAFA' : 'black'}>
                  {t('attention')}
                </Title>
                <Title
                  variant="highlight"
                  color={userDarkMode ? '#FAFAFA' : 'black'}
                >
                  {t('ultimate')}
                </Title>
              </Col>
              <Col className="mt-5" lg="9">
                <Text
                  style={{ lineHeight: '27px' }}
                  color={userDarkMode ? '#FAFAFA' : 'black'}
                >
                  {t('first')}
                </Text>
              </Col>
              <Col className="mt-3" lg="7">
                <EmailForm formType="mailLite" />
              </Col>
              <Col className="mt-3" lg="7">
                <Text
                  variant="small"
                  color={
                    userDarkMode
                      ? 'rgba(255, 255, 255, 0.56)'
                      : 'rgba(0, 0, 0, 0.56)'
                  }
                >
                  {t('byClicking')}
                </Text>
              </Col>
            </Container>
          </Section>
        </PageWrapper>
      </>
    )
  )
}

export default ComingSoon
