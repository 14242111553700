import SearchItemModel from '../../models/SearchItemModel'
import SearchMarketModel from '../../models/SearchMarketModel'
import BalanceModel from '../../models/BalanceModel'
import OrderModel from '../../models/OrderModel'
import { buildApiUrl } from '../../utils/helperFn'

export const SET_USER_CURR = 'SET_USER_CURR'
export const CHECK_PHP_CONNECTION_STATUS = 'CHECK_PHP_CONNECTION_STATUS'
export const SET_DARK_MODE = 'SET_DARK_MODE'
export const SET_HEADER_DARK = 'SET_HEADER_DARK'
export const GET_CSRF_TOKEN = 'GET_CSRF_TOKEN'
export const GET_USER_SETTINGS = 'GET_USER_SETTINGS'
export const SAVE_USER_CURR = 'SAVE_USER_CURR'
export const SAVE_USER_LANG = 'SAVE_USER_LANG'
export const SAVE_USER_DARK_MODE = 'SAVE_USER_DARK_MODE'
export const STORE_CSRF_COOKIE = 'STORE_CSRF_COOKIE'
export const STORE_NEW_TOKEN = 'STORE_NEW_TOKEN'
export const SAVE_BROWSER_LANG = 'SAVE_BROWSER_LANG'
export const SET_GLOBAL_SEARCH = 'SET_GLOBAL_SEARCH'
export const SAVE_FAV_MARKETS = 'SAVE_FAV_MARKETS'
export const GET_FAV_MARKETS_IDS = 'GET_FAV_MARKETS_IDS'
export const DELETE_FAV_MARKETS = 'DELETE_FAV_MARKETS'
export const SET_HIGH_CHARTS_RANGE = 'SET_HIGH_CHARTS_RANGE'
export const GET_AUTH_IDS = 'GET_AUTH_IDS'
export const GET_AUTH_EXCH_IDS = 'GET_AUTH_EXCH_IDS'
export const GET_USER_BALANCES = 'GET_USER_BALANCES'
export const GET_BALANCE_HISTORY = 'GET_BALANCE_HISTORY'
export const GET_USER_OPEN_ORDERS = 'GET_USER_OPEN_ORDERS'
export const GET_ORDER_TYPES = 'GET_ORDER_TYPES'
export const GET_ORDER_STATUS_TYPES = 'GET_ORDER_STATUS_TYPES'
export const GET_ORDERS_HISTORY = 'GET_ORDERS_HISTORY'
export const CANCEL_ORDER = 'CANCEL_ORDER'
export const GET_OHLC_PRICES = 'GET_OHLC_PRICES'
export const SET_TABLE_ROWS = 'SET_TABLE_ROWS'
export const SUGGEST_EDIT = 'SUGGEST_EDIT'
export const GET_USER_PREFS = 'GET_USER_PREFS'
export const PLACE_ORDER = 'PLACE_ORDER'
export const CHECK_USER_APPLICATIONS = 'CHECK_USER_APPLICATIONS'

export const fetchExchRate = selectedCurr => {
  let endpoint = buildApiUrl(`/public/convert/USD/${selectedCurr}`)

  // console.log('endpoint', endpoint)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint)

    let resData = await response.json()
    let exchRate
    let currSign

    exchRate = resData.result
    selectedCurr === 'USD'
      ? (currSign = '$')
      : selectedCurr === 'EUR'
      ? (currSign = '€')
      : selectedCurr === 'JPY'
      ? (currSign = '¥')
      : selectedCurr === 'GBP'
      ? (currSign = '£')
      : selectedCurr === 'KRW'
      ? (currSign = '₩')
      : selectedCurr === 'RUB'
      ? (currSign = '₽')
      : selectedCurr === 'BRL'
      ? (currSign = 'R$')
      : selectedCurr === 'ETH'
      ? (currSign = 'Ξ')
      : selectedCurr === 'BTC'
      ? (currSign = '₿')
      : (currSign = selectedCurr)

    dispatch({
      type: SET_USER_CURR,
      exchRate: exchRate,
      currSign: currSign,
      selectedCurr: selectedCurr,
    })
  }
}

export const checkPHPConnectionStatus = () => {
  let url = `${process.env.WWW_URL}/getjson/get_php_connection_status`

  return async (dispatch, getState) => {
    const response = await fetch(url, {
      credentials: 'include',
    })

    let resData = await response.json()

    let session_expired
    session_expired = resData.session_expired
    // console.log('PHP', resData);

    dispatch({
      type: CHECK_PHP_CONNECTION_STATUS,
      session_expired: session_expired,
    })
  }
}

export const fetchCsrf = () => {
  let endpoint = `${process.env.WWW_URL}/getjson/csrfv1`

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      credentials: 'include',
    })

    // console.log('FETCH CSRF RES', response)

    await response.text().then(function(text) {
      // console.log('FETCH CSRF RES TEXT', text)
      if (text.length > 0) {
        document.cookie = `coinigy_csrf_token=${text};domain=.coinigy.com;path=/`
        dispatch({
          type: GET_CSRF_TOKEN,
          csrf_token: text,
        })
      }
    })
  }
}

export const fetchUserSettings = () => {
  let endpoint = buildApiUrl(`/private/user/settings`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      headers: {
        // 'Content-Type': 'application/json',
        // 'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
    })

    let resData = await response.json()

    let user_settings
    user_settings = resData.result
    // console.log('USERSETTINGS', user_settings);

    dispatch({
      type: GET_USER_SETTINGS,
      user_settings: user_settings,
    })
  }
}

export const saveUserCurr = (csrf_token, curr) => {
  let endpoint = buildApiUrl(`/private/user/settings`)
  const bodyInputs = {
    pref_native_currency: curr,
  }

  // console.log('INPUTS', bodyInputs);

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
      body: JSON.stringify(bodyInputs),
    })

    let resData = await response.json()
    console.log('PUT RESDATA', resData)
    dispatch({
      type: SAVE_USER_CURR,
      bodyInputs,
    })
  }
}

export const saveUserLang = (csrf_token, lang, reg) => {
  // console.log('INACTION', reg)
  let endpoint = buildApiUrl(`/private/user/settings`)
  const bodyInputs = {
    language: lang,
    pref_lang_region: reg,
  }

  // console.log('INPUTS', bodyInputs);

  return async (dispatch, getState) => {
    await fetch(endpoint, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
      body: JSON.stringify(bodyInputs),
    })

    // let resData = await response.json();
    // console.log('PUT RESDATA', resData);
    dispatch({
      type: SAVE_USER_LANG,
      bodyInputs,
    })
  }
}

export const setDarkMode = darkMode => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_DARK_MODE,
      darkMode: darkMode,
    })
  }
}

export const saveUserDarkMode = (csrf_token, darkMode) => {
  let endpoint = buildApiUrl(`/private/user/settings`)
  const bodyInputs = {
    dark_mode: darkMode,
  }

  // console.log('INPUTS', bodyInputs);

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
      body: JSON.stringify(bodyInputs),
    })

    let resData = await response.json()
    console.log('PUT RESDATA', resData)
    dispatch({
      type: SAVE_USER_DARK_MODE,
      bodyInputs,
    })
  }
}

export const setHeaderDark = headerDark => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_HEADER_DARK,
      headerDark: headerDark,
    })
  }
}

export const storeCsrfCookie = csrfCookie => {
  return async (dispatch, getState) => {
    dispatch({
      type: STORE_CSRF_COOKIE,
      csrfCookie: csrfCookie,
    })
  }
}

export const storeNewToken = newToken => {
  return async (dispatch, getState) => {
    dispatch({
      type: STORE_NEW_TOKEN,
      newToken: newToken,
    })
  }
}

export const saveBrowserLang = browserLang => {
  return async (dispatch, getState) => {
    dispatch({
      type: SAVE_BROWSER_LANG,
      browserLang: browserLang,
    })
  }
}

export const globalSearch = item => {
  let endpoint = buildApiUrl(`/public/search`, { searchTerm: item })
  console.log(endpoint)
  return async (dispatch, getState) => {
    const response = await fetch(endpoint)

    let resData = await response.json()
    // console.log('SEARCH RESDATA', resData)
    const loadedExchs = []
    const loadedMkts = []
    const loadedCurrs = []
    // const loadedNews = [];
    if (resData.result.exchanges.length > 0) {
      for (const item in resData.result.exchanges) {
        loadedExchs.push(
          new SearchItemModel(
            resData.result.exchanges[item].exchName,
            resData.result.exchanges[item].exchCode,
            resData.result.exchanges[item].slug
          )
        )
      }
    }
    if (resData.result.markets.length > 0) {
      for (const item in resData.result.markets) {
        loadedMkts.push(
          new SearchMarketModel(
            resData.result.markets[item].displayName,
            resData.result.markets[item].marketName,
            resData.result.markets[item].exchCode,
            resData.result.markets[item].exchName,
            resData.result.markets[item].exchSlug
          )
        )
      }
    }
    if (resData.result.currencies.length > 0) {
      for (const item in resData.result.currencies) {
        loadedCurrs.push(
          new SearchItemModel(
            resData.result.currencies[item].currName,
            resData.result.currencies[item].currCode,
            resData.result.currencies[item].slug
          )
        )
      }
    }

    // if (resData.result.news.length > 1) {
    // 	for (const id in resData.result.news){
    // 		loadedNews.push(new NewsModel(
    // 				resData.result.news[id].id,
    // 				resData.result.news[id].url,
    // 				resData.result.news[id].title,
    // 				resData.result.news[id].pubDate,
    // 				resData.result.news[id].feedId,
    // 				resData.result.news[id].feedName,
    // 				resData.result.news[id].feedUrl,
    // 				resData.result.news[id].feedEnabled,
    // 				resData.result.news[id].feedDescription,
    // 				resData.result.news[id].feedImage,
    // 				resData.result.news[id].description
    // 			)
    // 		);
    // 	}
    // }
    // console.log('EXCH',loadedExchs, 'MKT', loadedMkts, 'CURR', loadedCurrs)
    dispatch({
      type: SET_GLOBAL_SEARCH,
      searchResultExchs: loadedExchs,
      searchResultMkts: loadedMkts,
      searchResultCurrs: loadedCurrs,
      // searchResultNews: loadedNews,
    })
  }
}

export const saveFavMarkets = (csrf_token, exchmktId) => {
  let endpoint = buildApiUrl(`/private/user/ticker`)
  let bodyInputs = {
    exchmktId: exchmktId,
  }

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
      body: JSON.stringify(bodyInputs),
    })
    // console.log('RESPOESEEEEE', response)
    let resData = await response.json()
    const favMarkets = resData.result
    dispatch({
      type: SAVE_FAV_MARKETS,
      favMarkets: favMarkets,
      bodyInputs,
    })
  }
}

export const fetchFavMarketIds = () => {
  let endpoint = buildApiUrl(`/private/user/ticker`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      headers: {
        // 'Content-Type': 'application/json',
        // 'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
    })

    let resData = await response.json()
    // console.log('resData', resData)
    let favMarketIds
    favMarketIds = resData.result
    // console.log('FAV MARKETS', favMarkets);

    dispatch({
      type: GET_FAV_MARKETS_IDS,
      favMarketIds: favMarketIds,
    })
  }
}

export const deleteFavMarkets = (csrf_token, exchmktId) => {
  let endpoint = buildApiUrl(`/private/user/ticker/${exchmktId}`)
  let bodyInputs = {
    exchmktId: exchmktId,
  }

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
      body: JSON.stringify(bodyInputs),
    })

    let resData = await response.json()
    const favMarkets = resData.result
    dispatch({
      type: DELETE_FAV_MARKETS,
      favMarkets: favMarkets,
      bodyInputs,
    })
  }
}

export const setHighChRange = highChartsRange => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_HIGH_CHARTS_RANGE,
      highChartsRange: highChartsRange,
    })
  }
}

export const setTableRows = tableRows => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_TABLE_ROWS,
      tableRows: tableRows,
    })
  }
}

export const fetchAuthIds = exchId => {
  let endpoint = buildApiUrl(`/private/user/accounts`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      headers: {
        // 'Content-Type': 'application/json',
        // 'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
    })

    let resData = await response.json()
    const authIds = []
    for (const id in resData.result) {
      if (resData.result[id].authExchId === exchId) {
        authIds.push(resData.result[id])
      }
    }

    // console.log('AUTHID', authIds)
    dispatch({
      type: GET_AUTH_IDS,
      authIds: authIds,
    })
  }
}

export const fetchAuthExchIds = () => {
  let endpoint = buildApiUrl(`/private/user/accounts`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      headers: {
        // 'Content-Type': 'application/json',
        // 'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
    })

    let resData = await response.json()
    const authExchIds = []
    for (const id in resData.result) {
      authExchIds.push(resData.result[id].authExchId)
    }
    // console.log('AUTHEXCHIDS', resData)
    dispatch({
      type: GET_AUTH_EXCH_IDS,
      authExchIds: authExchIds,
    })
  }
}

export const fetchUserBalances = () => {
  let endpoint = buildApiUrl(`/private/user/balances`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      headers: {
        // 'Content-Type': 'application/json',
        // 'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
    })

    let resData = await response.json()
    let balances
    balances = resData.result
    let loadedBalances = []
    // console.log('BALANCES', resData);
    function groupBy(objectArray, property) {
      return objectArray.reduce(function(acc, obj) {
        let key = obj[property]
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {})
    }

    let groupedBalances =
      balances !== null ? groupBy(balances, 'balanceCurrCode') : []

    Object.keys(groupedBalances).forEach(function(key, index) {
      let balTotalSum = 0
      let balCurrCode = ''
      let balCurrId = ''
      let balAmtAvailable = 0
      let balAmtHeld = 0
      let btcBal = 0
      let balLastTrade = 0
      let balQuote = ''
      let balPrice = 0
      let balCurrName = ''
      let balCurrSlug = ''
      let balCurrImgName = ''

      groupedBalances[key].forEach(function(bal) {
        balTotalSum += bal.balanceAmountTotal
        balCurrCode = bal.balanceCurrCode
        balCurrId = bal.balanceCurrId
        balAmtAvailable += bal.balanceAmountAvailable
        balAmtHeld += bal.balanceAmountHeld
        btcBal += bal.btcBalance
        balLastTrade = bal.lastTrade
        balQuote = bal.balanceQuoteCurrCode
        balPrice = bal.lastPrice
        balCurrName = bal.balanceCurrName
        balCurrSlug = bal.balanceCurrSlug
        balCurrImgName = bal.balanceCurrImgName
      })
      loadedBalances.push(
        new BalanceModel(
          balCurrCode,
          balCurrId,
          balAmtAvailable,
          balAmtHeld,
          balTotalSum,
          btcBal,
          balLastTrade,
          balQuote,
          balPrice,
          balCurrName,
          balCurrSlug,
          balCurrImgName
        )
      )
    })

    dispatch({
      type: GET_USER_BALANCES,
      balances: loadedBalances,
    })
  }
}

export const fetchBalancesHistory = (startDate, endDate) => {
  let endpoint = buildApiUrl(`/private/user/balances/history`, {
    StartDate: startDate,
    EndDate: endDate,
    QuoteCurrCode: 'BTC',
  })
  // console.log('endpoint', endpoint)
  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      headers: {
        // 'Content-Type': 'application/json',
        // 'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
    })

    let resData = await response.json()
    // console.log('resData', resData)
    function groupBy(objectArray, property) {
      return objectArray.reduce(function(acc, obj) {
        let key = obj[property]
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {})
    }
    let groupedBalances =
      resData.result !== null ? groupBy(resData.result, 'balanceDate') : []
    let loadedBalances = []
    Object.keys(groupedBalances).forEach(function(key, index) {
      let totalBtcBalOneDay = 0
      // let max = 0;
      for (const bal of groupedBalances[key]) {
        // max= bal.btcBalance
        totalBtcBalOneDay += bal.quoteBalance
        // if (max > 200) {
        // 	console.log('max', max, bal)
        // }
      }

      loadedBalances.push([new Date(key).getTime(), totalBtcBalOneDay])
    })
    loadedBalances.reverse()

    // console.log('LOADED BALANCES', loadedBalances)

    dispatch({
      type: GET_BALANCE_HISTORY,
      balHistory: loadedBalances,
    })
  }
}

export const fetchUserOpenOrders = () => {
  let endpoint = buildApiUrl(`/private/user/orders`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      headers: {
        // 'Content-Type': 'application/json',
        // 'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
    })

    let resData = await response.json()
    let loadedOrders = []
    // console.log('ORDERS', resData)
    for (const order in resData.result) {
      loadedOrders.push(
        new OrderModel(
          resData.result[order].coinigyOrderId,
          resData.result[order].displayName,
          resData.result[order].market,
          resData.result[order].openTime,
          resData.result[order].closeTime,
          resData.result[order].exchange,
          resData.result[order].limitPrice,
          resData.result[order].stopPrice,
          resData.result[order].baseCurrency,
          resData.result[order].quoteCurrency,
          resData.result[order].exchCode,
          resData.result[order].orderType,
          resData.result[order].quantity,
          resData.result[order].status,
          resData.result[order].authId,
          resData.result[order].side
        )
      )
    }
    dispatch({
      type: GET_USER_OPEN_ORDERS,
      openOrders: loadedOrders,
    })
  }
}
export const fetchOrderTypes = () => {
  let endpoint = buildApiUrl(`/private/orderPriceTypes`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      headers: {
        // 'Content-Type': 'application/json',
        // 'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
    })

    let resData = await response.json()
    let orderTypes
    orderTypes = resData.result
    // console.log('TYPES', resData);

    dispatch({
      type: GET_ORDER_TYPES,
      orderTypes: orderTypes,
    })
  }
}

export const fetchOrderStatusTypes = () => {
  let endpoint = buildApiUrl(`/private/orderStatusTypes`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      headers: {
        // 'Content-Type': 'application/json',
        // 'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
    })

    let resData = await response.json()
    let orderStatusTypes
    orderStatusTypes = resData.result
    // console.log('STATUS', resData);

    dispatch({
      type: GET_ORDER_STATUS_TYPES,
      orderStatusTypes: orderStatusTypes,
    })
  }
}

export const fetchOrdersHistory = () => {
  let endpoint = buildApiUrl(`/private/user/orders/history`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      headers: {
        // 'Content-Type': 'application/json',
        // 'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
    })

    let resData = await response.json()
    let loadedOrders = []
    // console.log('HISTORY', resData);
    for (const order in resData.result) {
      loadedOrders.push(
        new OrderModel(
          resData.result[order].coinigyOrderId,
          resData.result[order].displayName,
          resData.result[order].market,
          resData.result[order].openTime,
          resData.result[order].closeTime,
          resData.result[order].exchange,
          resData.result[order].limitPrice,
          resData.result[order].stopPrice,
          resData.result[order].baseCurrency,
          resData.result[order].quoteCurrency,
          resData.result[order].exchCode,
          resData.result[order].orderType,
          resData.result[order].quantity,
          resData.result[order].status
        )
      )
    }
    dispatch({
      type: GET_ORDERS_HISTORY,
      ordersHistory: loadedOrders,
    })
  }
}
export const cancelOrder = (csrf_token, authId, coinigyOrderId) => {
  let endpoint = buildApiUrl(
    `/private/user/accounts/${authId}/orders/${coinigyOrderId}`
  )

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
    })

    let resData = await response.json()
    console.log('DELETE RESDATA', resData)
    dispatch({
      type: CANCEL_ORDER,
    })
  }
}

export const getOhlcPrice = (exchange, market, startDate, endDate) => {
  let endpoint = buildApiUrl(
    `/private/exchanges/${exchange}/markets/${market}/ohlc/d`,
    {
      StartDate: startDate,
      EndDate: endDate,
    }
  )

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      headers: {
        // 'Content-Type': 'application/json',
        // 'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
    })

    let resData = await response.json()
    // console.log('OHLC', resData);
    let ohlcPrices = []
    for (const candle in resData.result) {
      ohlcPrices.push(resData.result[candle].close)
    }

    dispatch({
      type: GET_OHLC_PRICES,
      ohlcPrices: ohlcPrices,
    })
  }
}

export const suggestEdit = (csrf_token, type, location, inputValue) => {
  let endpoint = buildApiUrl(`/private/bugReport`)
  const bodyInputs = {
    type: type,
    url: location,
    message: inputValue,
  }

  return async (dispatch, getState) => {
    await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
      body: JSON.stringify(bodyInputs),
    })

    // let resData = await response.json();
    dispatch({
      type: SUGGEST_EDIT,
      bodyInputs,
    })
  }
}

export const fetchUserPrefs = () => {
  let endpoint = buildApiUrl(`/private/user`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      headers: {
        // 'Content-Type': 'application/json',
        // 'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
    })

    let resData = await response.json()

    let userPrefs
    userPrefs = resData.result.userPrefs
    // console.log('USERPREFS', userPrefs);

    dispatch({
      type: GET_USER_PREFS,
      userPrefs: userPrefs,
    })
  }
}

export const placeOrder = (
  csrf_token,
  authId,
  exchmktId,
  orderType,
  orderSide,
  orderPrice,
  quantity
) => {
  let endpoint = buildApiUrl(`/private/user/accounts/${authId}/orders`)

  const bodyInputs = {
    ExchMktId: exchmktId,
    // orderType: 5,
    side: orderSide,
    priceType: orderType,
    price: orderPrice,
    quantity: quantity,
    // conditionalOperator: 0,
    // triggerPrice: 57000,
    // triggerExchMktId: exchmktId,
    // expiresAt: '2021-12-30T17:39:50.317Z',
    // trailingType: 0,
    // trailingGap: 0
  }
  // let body: V2_Order = {
  //   ExchMktId: market.exchmktId,
  //   priceType: priceTypeId,
  //   side: orderTypeText,
  //   price: parseFloat(limitPrice),
  //   quantity: orderTypeId == 1 ?
  //     parseFloat(orderTotal) :
  //     parseFloat(orderQty)
  // };

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
      body: JSON.stringify(bodyInputs),
    })

    let resData = await response.json()
    console.log('POST ORDER RESDATA', resData)
    dispatch({
      type: PLACE_ORDER,
      orderResponse: resData,
    })
  }
}

export const checkUserApplications = () => {
  let endpoint = buildApiUrl(`/private/user/applications`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint, {
      headers: {
        // 'Content-Type': 'application/json',
        // 'X-CSRF-TOKEN': csrf_token,
      },
      credentials: 'include',
    })

    let resData = await response.json()
    // console.log('RES', resData);

    dispatch({
      type: CHECK_USER_APPLICATIONS,
      user_applications: resData.result,
    })
  }
}
