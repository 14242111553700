class UserBalance {
  constructor(
    balanceCurrCode,
    balanceCurrId,
    balanceAmountAvailable,
    balanceAmountHeld,
    balanceAmountTotal,
    btcBalance,
    lastTrade,
    balanceQuoteCurrCode,
    lastPrice,
    balanceCurrName,
    balanceCurrSlug,
    balanceCurrImgName
  ) {
    this.balanceCurrCode =
      typeof balanceCurrCode === 'undefined' ? '' : balanceCurrCode
    this.balanceCurrId =
      typeof balanceCurrId === 'undefined' ? '' : balanceCurrId
    this.balanceAmountAvailable =
      typeof balanceAmountAvailable === 'undefined'
        ? ''
        : balanceAmountAvailable
    this.balanceAmountHeld =
      typeof balanceAmountHeld === 'undefined' ? '' : balanceAmountHeld
    this.balanceAmountTotal =
      typeof balanceAmountTotal === 'undefined' ? '' : balanceAmountTotal
    this.btcBalance = typeof btcBalance === 'undefined' ? '' : btcBalance
    this.lastTrade = typeof lastTrade === 'undefined' ? '' : lastTrade
    this.balanceQuoteCurrCode =
      typeof balanceQuoteCurrCode === 'undefined' ? '' : balanceQuoteCurrCode
    this.lastPrice = typeof lastPrice === 'undefined' ? '' : lastPrice
    this.balanceCurrName =
      typeof balanceCurrName === 'undefined' ? '' : balanceCurrName
    this.balanceCurrSlug =
      typeof balanceCurrSlug === 'undefined' ? '' : balanceCurrSlug
    this.balanceCurrImgName =
      typeof balanceCurrImgName === 'undefined' ? '' : balanceCurrImgName
  }
}

export default UserBalance
