import defaultImg from '../../assets/image/png/Cover-blue.png'

const metaConfig = (page, param) => {
  const customParam = param || ''
  const BASE_URL = process.env.WWW_URL

  const metaContent = {
    default: {
      title_tag: `Coinigy - Your all-in-one Digital Wallet portfolio management platform`,
      meta_title: `Coinigy - Your all-in-one Digital Wallet portfolio management platform`,
      meta_description: `Coinigy is a powerful and intuitive tool for managing your digital wallet portfolio. Coinigy interface provides real-time market data, charting tools with instant market notifications across all major exchanges in one place—and it does so much more!`,

      meta_og_type: `website`,
      meta_og_url: `${BASE_URL}`,
      meta_og_title: `Coinigy - Your all-in-one Digital Wallet portfolio management platform`,
      meta_og_description: `Take your bitcoin trading to the next level! Coinigy is your all-in-one platform for digital currency. Trade on 45+ of the most popular bitcoin exchanges from one secure account on both desktop and mobile.`,
      meta_og_image: `${BASE_URL}${defaultImg}`,

      meta_tw_card: `summary_large_image`,
      meta_tw_url: `${BASE_URL}`,
      meta_tw_title: `Coinigy - Your all-in-one Digital Wallet portfolio management platform`,
      meta_tw_description: `Take your bitcoin trading to the next level! Coinigy is your all-in-one platform for digital currency. Trade on 45+ of the most popular bitcoin exchanges from one secure account on both desktop and mobile.`,
      meta_tw_image: `${BASE_URL}${defaultImg}`,
      en_page_url: `${BASE_URL}/en`,
      es_page_url: `${BASE_URL}/es`,
    },

    features: {
      title_tag: `Features - Coinigy, Professional Bitcoin & Cryptocurrency Trading Platform`,
      meta_title: `Features - Coinigy, Professional Bitcoin & Cryptocurrency Trading Platform`,
      meta_description: `Take your bitcoin trading to the next level! Coinigy is your all-in-one platform for digital currency. Trade on 45+ of the most popular bitcoin exchanges from one secure account on both desktop and mobile.`,

      meta_og_type: `website`,
      meta_og_url: `${BASE_URL}`,
      meta_og_title: `Features - Coinigy, Professional Bitcoin & Cryptocurrency Trading Platform`,
      meta_og_description: `Take your bitcoin trading to the next level! Coinigy is your all-in-one platform for digital currency. Trade on 45+ of the most popular bitcoin exchanges from one secure account on both desktop and mobile. `,
      meta_og_image: `${BASE_URL}${defaultImg}`,

      meta_tw_card: `summary_large_image`,
      meta_tw_url: `${BASE_URL}`,
      meta_tw_title: `Features - Coinigy, Professional Bitcoin & Cryptocurrency Trading Platform`,
      meta_tw_description: `Take your bitcoin trading to the next level! Coinigy is your all-in-one platform for digital currency. Trade on 45+ of the most popular bitcoin exchanges from one secure account on both desktop and mobile. `,
      meta_tw_image: `${BASE_URL}${defaultImg}`,
      en_page_url: `${BASE_URL}/en/why-us`,
      es_page_url: `${BASE_URL}/es/why-us`,
    },

    platform: {
      title_tag: `Trading platform features & tools - Coinigy`,
      meta_title: `Trading platform features & tools - Coinigy`,
      meta_description: `Manage multiple portfolios across different exchanges from one central location. Connect over 45 exchanges and manage 5000 different cryptocurrencies`,

      meta_og_type: `website`,
      meta_og_url: `${BASE_URL}`,
      meta_og_title: `Trading platform features & tools - Coinigy`,
      meta_og_description: `Manage multiple portfolios across different exchanges from one central location. Connect over 45 exchanges and manage 5000 different cryptocurrencies`,
      meta_og_image: `${BASE_URL}${defaultImg}`,

      meta_tw_card: `summary_large_image`,
      meta_tw_url: `${BASE_URL}`,
      meta_tw_title: `Trading platform features & tools - Coinigy`,
      meta_tw_description: `Manage multiple portfolios across different exchanges from one central location. Connect over 45 exchanges and manage 5000 different cryptocurrencies`,
      meta_tw_image: `${BASE_URL}${defaultImg}`,
      en_page_url: `${BASE_URL}/en/platform`,
      es_page_url: `${BASE_URL}/es/platform`,
    },

    portfolioManagement: {
      title_tag: `Portfolio management across multiple exchanges - Coinigy`,
      meta_title: `Portfolio management across multiple exchanges - Coinigy`,
      meta_description: `Manage multiple portfolios across different exchanges from one central location. Connect over 45 exchanges and manage 5000 different cryptocurrencies`,

      meta_og_type: `website`,
      meta_og_url: `${BASE_URL}`,
      meta_og_title: `Portfolio management across multiple exchanges - Coinigy`,
      meta_og_description: `Manage multiple portfolios across different exchanges from one central location. Connect over 45 exchanges and manage 5000 different cryptocurrencies`,
      meta_og_image: `${BASE_URL}${defaultImg}`,

      meta_tw_card: `summary_large_image`,
      meta_tw_url: `${BASE_URL}`,
      meta_tw_title: `Portfolio management across multiple exchanges - Coinigy`,
      meta_tw_description: `Manage multiple portfolios across different exchanges from one central location. Connect over 45 exchanges and manage 5000 different cryptocurrencies`,
      meta_tw_image: `${BASE_URL}${defaultImg}`,
      en_page_url: `${BASE_URL}/en/portfolio-management`,
      es_page_url: `${BASE_URL}/es/portfolio-management`,
    },

    pricing: {
      title_tag: `Pricing - Coinigy, Professional Bitcoin & Cryptocurrency Trading Platform`,
      meta_title: `Pricing - Coinigy, Professional Bitcoin & Cryptocurrency Trading Platform`,
      meta_description: `Take your bitcoin trading to the next level! Coinigy is your all-in-one platform for digital currency. Trade on 45+ of the most popular bitcoin exchanges from one secure account on both desktop and mobile.`,

      meta_og_type: `website`,
      meta_og_url: `${BASE_URL}`,
      meta_og_title: `Pricing - Coinigy, Professional Bitcoin & Cryptocurrency Trading Platform`,
      meta_og_description: `Take your bitcoin trading to the next level! Coinigy is your all-in-one platform for digital currency. Trade on 45+ of the most popular bitcoin exchanges from one secure account on both desktop and mobile.`,
      meta_og_image: `${BASE_URL}${defaultImg}`,

      meta_tw_card: `summary_large_image`,
      meta_tw_url: `${BASE_URL}`,
      meta_tw_title: `Pricing - Coinigy, Professional Bitcoin & Cryptocurrency Trading Platform`,
      meta_tw_description: `Take your bitcoin trading to the next level! Coinigy is your all-in-one platform for digital currency. Trade on 45+ of the most popular bitcoin exchanges from one secure account on both desktop and mobile.`,
      meta_tw_image: `${BASE_URL}${defaultImg}`,
      en_page_url: `${BASE_URL}/en/pricing`,
      es_page_url: `${BASE_URL}/es/pricing`,
    },

    exchanges: {
      title_tag: `Top Crypto Exchanges Ranked By Popularity & Volume - Coinigy`,
      meta_title: `Top Crypto Exchanges Ranked By Popularity & Volume - Coinigy`,
      meta_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,

      meta_og_type: `website`,
      meta_og_url: `${BASE_URL}`,
      meta_og_title: `Top Crypto Exchanges Ranked By Popularity & Volume - Coinigy`,
      meta_og_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,
      meta_og_image: `${BASE_URL}${defaultImg}`,

      meta_tw_card: `summary_large_image`,
      meta_tw_url: `${BASE_URL}`,
      meta_tw_title: `Top Crypto Exchanges Ranked By Popularity & Volume - Coinigy`,
      meta_tw_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,
      meta_tw_image: `${BASE_URL}${defaultImg}`,
      en_page_url: `${BASE_URL}/en/exchanges`,
      es_page_url: `${BASE_URL}/es/exchanges`,
    },

    supportedExchanges: {
      title_tag: `Supported exchanges & features - Coinigy`,
      meta_title: `Supported exchanges & features - Coinigy`,
      meta_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,

      meta_og_type: `website`,
      meta_og_url: `${BASE_URL}`,
      meta_og_title: `Supported exchanges & features - Coinigy`,
      meta_og_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,
      meta_og_image: `${BASE_URL}${defaultImg}`,

      meta_tw_card: `summary_large_image`,
      meta_tw_url: `${BASE_URL}`,
      meta_tw_title: `Supported exchanges & features - Coinigy`,
      meta_tw_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,
      meta_tw_image: `${BASE_URL}${defaultImg}`,
      en_page_url: `${BASE_URL}/en/supported-exchanges`,
      es_page_url: `${BASE_URL}/es/supported-exchanges`,
    },

    exchSlug: {
      title_tag: `${customParam.exchName} Markets, Cryptocurrencies, Trade Volume & FAQs - Coinigy`,
      meta_title: `${customParam.exchName} Markets, Cryptocurrencies, Trade Volume & FAQs - Coinigy`,
      meta_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,

      meta_og_type: `website`,
      meta_og_url: `${BASE_URL}`,
      meta_og_title: `${customParam.exchName} Markets, Cryptocurrencies, Trade Volume & FAQs - Coinigy`,
      meta_og_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,
      meta_og_image: `${BASE_URL}${defaultImg}`,

      meta_tw_card: `summary_large_image`,
      meta_tw_url: `${BASE_URL}`,
      meta_tw_title: `${customParam.exchName} Markets, Cryptocurrencies, Trade Volume & FAQs - Coinigy`,
      meta_tw_description: `See our list of cryptocurrency exchanges ✔️ Ranked by volume ✔️ Binance ✔️ Coinbase Pro ✔️ Huobi ✔️ Kraken ✔️ Gemini✔️ FTX ✔️Bitfinex ✔️ And many more ✔️`,
      meta_tw_image: `${BASE_URL}${defaultImg}`,
      en_page_url: `${BASE_URL}/en/exchanges/${customParam.locationExchSlug}`,
      es_page_url: `${BASE_URL}/es/exchanges/${customParam.locationExchSlug}`,
    },

    cryptocurrencies: {
      title_tag: `Top Cryptocurrencies Ranked By Popularity & Volume - Coinigy`,
      meta_title: `Top Cryptocurrencies Ranked By Popularity & Volume - Coinigy`,
      meta_description: `Top cryptocurrency prices and charts, listed by market capitalization. Free access to current and historic data for Bitcoin and thousands of altcoins`,

      meta_og_type: `website`,
      meta_og_url: `${BASE_URL}`,
      meta_og_title: `Top Cryptocurrencies Ranked By Popularity & Volume - Coinigy`,
      meta_og_description: `Top cryptocurrency prices and charts, listed by market capitalization. Free access to current and historic data for Bitcoin and thousands of altcoins`,
      meta_og_image: `${BASE_URL}${defaultImg}`,

      meta_tw_card: `summary_large_image`,
      meta_tw_url: `${BASE_URL}`,
      meta_tw_title: `Top Cryptocurrencies Ranked By Popularity & Volume - Coinigy`,
      meta_tw_description: `Top cryptocurrency prices and charts, listed by market capitalization. Free access to current and historic data for Bitcoin and thousands of altcoins`,
      meta_tw_image: `${BASE_URL}${defaultImg}`,
      en_page_url: `${BASE_URL}/en/cryptocurrencies`,
      es_page_url: `${BASE_URL}/es/cryptocurrencies`,
    },

    currSlug: {
      title_tag: `${customParam.currName} (${customParam.code}) Price Chart & Market Cap - Coinigy`,
      meta_title: `${customParam.currName} (${customParam.code}) Price Chart & Market Cap - Coinigy`,
      meta_description: `Top cryptocurrency prices and charts, listed by market capitalization. Free access to current and historic data for Bitcoin and thousands of altcoins`,

      meta_og_type: `website`,
      meta_og_url: `${BASE_URL}`,
      meta_og_title: `${customParam.currName} (${customParam.code}) Price Chart & Market Cap - Coinigy`,
      meta_og_description: `Top cryptocurrency prices and charts, listed by market capitalization. Free access to current and historic data for Bitcoin and thousands of altcoins`,
      meta_og_image: `${BASE_URL}${defaultImg}`,

      meta_tw_card: `summary_large_image`,
      meta_tw_url: `${BASE_URL}`,
      meta_tw_title: `${customParam.currName} (${customParam.code}) Price Chart & Market Cap - Coinigy`,
      meta_tw_description: `Top cryptocurrency prices and charts, listed by market capitalization. Free access to current and historic data for Bitcoin and thousands of altcoins`,
      meta_tw_image: `${BASE_URL}${defaultImg}`,
      en_page_url: `${BASE_URL}/en/cryptocurrencies/${customParam.locationCurrSlug}`,
      es_page_url: `${BASE_URL}/es/cryptocurrencies/${customParam.locationCurrSlug}`,
    },

    markets: {
      title_tag: `Top Crypto Markets Ranked By Popularity & Volume - Coinigy`,
      meta_title: `Top Crypto Markets Ranked By Popularity & Volume - Coinigy`,
      meta_description: `Trade Bitcoin and Ethereum and view other markets available to trade via our innovative external exchange connector technology - Coinigy`,

      meta_og_type: `website`,
      meta_og_url: `${BASE_URL}`,
      meta_og_title: `Top Crypto Markets Ranked By Popularity & Volume - Coinigy`,
      meta_og_description: `Trade Bitcoin and Ethereum and view other markets available to trade via our innovative external exchange connector technology - Coinigy`,
      meta_og_image: `${BASE_URL}${defaultImg}`,

      meta_tw_card: `summary_large_image`,
      meta_tw_url: `${BASE_URL}`,
      meta_tw_title: `Top Crypto Markets Ranked By Popularity & Volume - Coinigy`,
      meta_tw_description: `Trade Bitcoin and Ethereum and view other markets available to trade via our innovative external exchange connector technology - Coinigy`,
      meta_tw_image: `${BASE_URL}${defaultImg}`,
      en_page_url: `${BASE_URL}/en/markets`,
      es_page_url: `${BASE_URL}/es/markets`,
    },

    marketSlug: {
      title_tag: `${customParam.exchName} ${customParam.marketName} Price Chart & Volume - Coinigy`,
      meta_title: `${customParam.exchName} ${customParam.marketName} Price Chart & Volume - Coinigy`,
      meta_description: `Trade Bitcoin and Ethereum and view other markets available to trade via our innovative external exchange connector technology - Coinigy`,

      meta_og_type: `website`,
      meta_og_url: `${BASE_URL}`,
      meta_og_title: `${customParam.exchName} ${customParam.marketName} Price Chart & Volume - Coinigy`,
      meta_og_description: `Trade Bitcoin and Ethereum and view other markets available to trade via our innovative external exchange connector technology - Coinigy`,
      meta_og_image: `${BASE_URL}${defaultImg}`,

      meta_tw_card: `summary_large_image`,
      meta_tw_url: `${BASE_URL}`,
      meta_tw_title: `${customParam.exchName} ${customParam.marketName} Price Chart & Volume - Coinigy`,
      meta_tw_description: `Trade Bitcoin and Ethereum and view other markets available to trade via our innovative external exchange connector technology - Coinigy`,
      meta_tw_image: `${BASE_URL}${defaultImg}`,
      en_page_url: `${BASE_URL}/en/markets/${customParam.locationExchSlug}/${customParam.marketName}`,
      es_page_url: `${BASE_URL}/es/markets/${customParam.locationExchSlug}/${customParam.marketName}`,
    },

    security: {
      title_tag: `Security information - Coinigy`,
      meta_title: `Security information - Coinigy`,
      meta_description: `Take your bitcoin trading to the next level! Coinigy is your all-in-one platform for digital currency. Trade on 45+ of the most popular bitcoin exchanges from one secure account on both desktop and mobile.`,

      meta_og_type: `website`,
      meta_og_url: `${BASE_URL}`,
      meta_og_title: `Security information - Coinigy`,
      meta_og_description: `Take your bitcoin trading to the next level! Coinigy is your all-in-one platform for digital currency. Trade on 45+ of the most popular bitcoin exchanges from one secure account on both desktop and mobile.`,
      meta_og_image: `${BASE_URL}${defaultImg}`,

      meta_tw_card: `summary_large_image`,
      meta_tw_url: `${BASE_URL}`,
      meta_tw_title: `Security information - Coinigy`,
      meta_tw_description: `Take your bitcoin trading to the next level! Coinigy is your all-in-one platform for digital currency. Trade on 45+ of the most popular bitcoin exchanges from one secure account on both desktop and mobile.`,
      meta_tw_image: `${BASE_URL}${defaultImg}`,
      en_page_url: `${BASE_URL}/en/security`,
      es_page_url: `${BASE_URL}/es/security`,
    },

    support: {
      title_tag: `Contact, Support & FAQs - Coinigy`,
      meta_title: `Contact, Support & FAQs - Coinigy`,
      meta_description: `Coinigy provides comprehensive FAQs and support. Schedule a call or send us a message anytime. Our client experience team is here to help`,

      meta_og_type: `website`,
      meta_og_url: `${BASE_URL}`,
      meta_og_title: `Contact, Support & FAQs - Coinigy`,
      meta_og_description: `Coinigy provides comprehensive FAQs and support. Schedule a call or send us a message anytime. Our client experience team is here to help`,
      meta_og_image: `${BASE_URL}${defaultImg}`,

      meta_tw_card: `summary_large_image`,
      meta_tw_url: `${BASE_URL}`,
      meta_tw_title: `Contact, Support & FAQs - Coinigy`,
      meta_tw_description: `Coinigy provides comprehensive FAQs and support. Schedule a call or send us a message anytime. Our client experience team is here to help`,
      meta_tw_image: `${BASE_URL}${defaultImg}`,
      en_page_url: `${BASE_URL}/en/support`,
      es_page_url: `${BASE_URL}/es/support`,
    },
  }

  return metaContent[page]
}

export default metaConfig
