import NewsModel from '../../models/NewsModel'
import { buildApiUrl } from '../../utils/helperFn'

export const SET_LATEST_NEWS = 'SET_LATEST_NEWS'
export const SET_NEWS_DETAIL = 'SET_NEWS_DETAIL'

export const fetchLatestNews = () => {
  let endpoint = buildApiUrl(`/public/news`)

  return async (dispatch, getState) => {
    const response = await fetch(endpoint)

    let resData = await response.json()
    const loadedNews = []
    for (const id in resData.result) {
      loadedNews.push(
        new NewsModel(
          resData.result[id].id,
          resData.result[id].url,
          resData.result[id].title,
          resData.result[id].pubDate,
          resData.result[id].feedId,
          resData.result[id].feedName,
          resData.result[id].feedUrl,
          resData.result[id].feedEnabled,
          resData.result[id].feedDescription,
          resData.result[id].feedImage,
          resData.result[id].description
        )
      )
    }
    dispatch({
      type: SET_LATEST_NEWS,
      latestNews: loadedNews,
    })
  }
}

export const fetchNewsDetail = item => {
  let endpoint = buildApiUrl(`/public/search?searchTerm=${item}`)
  return async (dispatch, getState) => {
    const response = await fetch(endpoint)

    let resData = await response.json()
    // console.log('NEWS RESDATA', resData)
    const loadedNews = []
    if (resData.result.news.length > 1) {
      for (const id in resData.result.news) {
        loadedNews.push(
          new NewsModel(
            resData.result.news[id].id,
            resData.result.news[id].url,
            resData.result.news[id].title,
            resData.result.news[id].pubDate,
            resData.result.news[id].feedId,
            resData.result.news[id].feedName,
            resData.result.news[id].feedUrl,
            resData.result.news[id].feedEnabled,
            resData.result.news[id].feedDescription,
            resData.result.news[id].feedImage,
            resData.result.news[id].description
          )
        )
      }
    } else if (resData.result.news.length < 1) {
      let endpoint = buildApiUrl(`/public/news`)
      const response = await fetch(endpoint)
      let resData = await response.json()
      // console.log('NEWS RESDATA 2222', resData)
      for (const id in resData.result) {
        loadedNews.push(
          new NewsModel(
            resData.result[id].id,
            resData.result[id].url,
            resData.result[id].title,
            resData.result[id].pubDate,
            resData.result[id].feedId,
            resData.result[id].feedName,
            resData.result[id].feedUrl,
            resData.result[id].feedEnabled,
            resData.result[id].feedDescription,
            resData.result[id].feedImage,
            resData.result[id].description
          )
        )
      }
    }

    dispatch({
      type: SET_NEWS_DETAIL,
      newsDetail: loadedNews,
    })
  }
}
