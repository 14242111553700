class News {
  constructor(
    id,
    url,
    title,
    pubDate,
    feedId,
    feedName,
    feedUrl,
    feedEnabled,
    feedDescription,
    feedImage,
    description
  ) {
    this.id = typeof id === 'undefined' ? '' : id
    this.url = typeof url === 'undefined' ? '' : url
    this.title = typeof title === 'undefined' ? '' : title
    this.pubDate = typeof pubDate === 'undefined' ? '' : pubDate
    this.feedId = typeof feedId === 'undefined' ? '' : feedId
    this.feedName = typeof feedName === 'undefined' ? '' : feedName
    this.feedUrl = typeof feedUrl === 'undefined' ? '' : feedUrl
    this.feedEnabled = typeof feedEnabled === 'undefined' ? '' : feedEnabled
    this.feedDescription =
      typeof feedDescription === 'undefined' ? '' : feedDescription
    this.feedImage = typeof feedImage === 'undefined' ? '' : feedImage
    this.description = typeof description === 'undefined' ? '' : description
  }
}

export default News
