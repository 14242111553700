class OHLC {
  constructor(open, high, low, close, volume, startTime, endTime, exchCode) {
    this.open = typeof open === 'undefined' ? '' : open
    this.high = typeof high === 'undefined' ? '' : high
    this.low = typeof low === 'undefined' ? '' : low
    this.close = typeof close === 'undefined' ? '' : close
    this.volume = typeof volume === 'undefined' ? '' : volume
    this.startTime = typeof startTime === 'undefined' ? '' : startTime
    this.endTime = typeof endTime === 'undefined' ? '' : endTime
    this.exchCode = typeof exchCode === 'undefined' ? '' : exchCode
  }
}

export default OHLC
