import React from "react";
import { GlobalProvider } from "./src/context/GlobalContext";
import Layout from "./src/components/Layout";
import MetaData from "./src/components/MetaData"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { defaultLanguage, supportedLanguages } from "./constants";

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};

const context = {};

export const onRenderBody = ({
  setHeadComponents,
  setHtmlAttributes,
  setBodyAttributes
}) => {
  const { helmet } = context;

  if (helmet) {
    const baseComponent = helmet.base.toComponent();
    const titleComponent = helmet.title.toComponent();
    const components = [
      helmet.priority.toComponent(),
      helmet.meta.toComponent(),
      helmet.link.toComponent(),
      helmet.style.toComponent(),
      helmet.script.toComponent(),
      helmet.noscript.toComponent()
    ];

    setHeadComponents(
      titleComponent[0]?.props.children
        ? [baseComponent, titleComponent, ...components]
        : [baseComponent, ...components]
    );

    setHtmlAttributes(helmet.htmlAttributes.toComponent());
    setBodyAttributes(helmet.bodyAttributes.toComponent());
  }
};


export const wrapRootElement = ({ element, pathname }) => {

  const siteUrl = process.env.WWW_URL

  const { props} = element;

  const lang = props && props.pageContext && props.pageContext.lang || defaultLanguage;

  const originalPath = props && props.pageContext && props.pageContext.originalPath || pathname;

  const canonicalUrl = `${siteUrl}/${lang}${originalPath}`;

  const languageFallbackUrl = `${siteUrl}${originalPath}`;

  return (
    <HelmetProvider context={context}>
      <Helmet htmlAttributes={{ lang }}>
        <meta property="og:locale" content={lang} />
        <link rel="canonical" href={canonicalUrl} />
        <link rel="alternate" href={languageFallbackUrl} hrefLang="x-default" />
        {supportedLanguages.map(supportedLang => (
          <link
            rel="alternate"
            href={`${siteUrl}/${supportedLang}${originalPath}`}
            hrefLang={supportedLang}
            key={supportedLang}
          />
        ))}
      </Helmet>
      <MetaData page="default" />
      <GlobalProvider>{element}</GlobalProvider>
    </HelmetProvider>
  )
}
