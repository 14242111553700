import { SET_LATEST_NEWS, SET_NEWS_DETAIL } from '../actions/newsActions'

const initialState = {
  latestNews: [],
  newsDetail: [],
}

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LATEST_NEWS:
      return {
        ...state,
        latestNews: action.latestNews,
      }
    case SET_NEWS_DETAIL:
      return {
        ...state,
        newsDetail: action.newsDetail,
      }
    default:
      return state
  }
}

export default newsReducer
