class ExchDetail {
  constructor(
    exchId,
    exchName,
    exchJSON,
    exchUrl,
    exchCode,
    heatIndex,
    exchNumCurrencies,
    exchNumMarkets,
    exchVolume24,
    exchBalanceEnabledV2,
    exchTradeEnabledV2,
    hasSpotTrading,
    hasFuturesTrading,
    prevSlug,
    nextSlug,
    prevSlugName,
    nextSlugName
  ) {
    this.exchId = typeof exchId === 'undefined' ? '' : exchId
    this.exchName = typeof exchName === 'undefined' ? '' : exchName
    this.exchJSON = typeof exchJSON === 'undefined' ? '' : exchJSON
    this.exchUrl = typeof exchUrl === 'undefined' ? '' : exchUrl
    this.exchCode = typeof exchCode === 'undefined' ? '' : exchCode
    this.heatIndex = typeof heatIndex === 'undefined' ? '' : heatIndex
    this.exchNumCurrencies =
      typeof exchNumCurrencies === 'undefined' ? '' : exchNumCurrencies
    this.exchNumMarkets =
      typeof exchNumMarkets === 'undefined' ? '' : exchNumMarkets
    this.exchVolume24 = typeof exchVolume24 === 'undefined' ? '' : exchVolume24
    // this.enFaqsKeys = typeof enFaqsKeys === 'undefined' ? '' : enFaqsKeys;
    // this.enFaqsValues = typeof enFaqsValues === 'undefined' ? '' : enFaqsValues;
    // this.esFaqsKeys = typeof esFaqsKeys === 'undefined' ? '' : esFaqsKeys;
    // this.esFaqsValues = typeof this.esFaqsValues === 'undefined' ? '' : esFaqsValues;
    this.exchBalanceEnabledV2 =
      typeof exchBalanceEnabledV2 === 'undefined' ? '' : exchBalanceEnabledV2
    this.exchTradeEnabledV2 =
      typeof exchTradeEnabledV2 === 'undefined' ? '' : exchTradeEnabledV2
    this.hasSpotTrading =
      typeof hasSpotTrading === 'undefined' ? '' : hasSpotTrading
    this.hasFuturesTrading =
      typeof hasFuturesTrading === 'undefined' ? '' : hasFuturesTrading
    this.prevSlug = typeof prevSlug === 'undefined' ? '' : prevSlug
    this.nextSlug = typeof nextSlug === 'undefined' ? '' : nextSlug
    this.prevSlugName = typeof prevSlugName === 'undefined' ? '' : prevSlugName
    this.nextSlugName = typeof nextSlugName === 'undefined' ? '' : nextSlugName
  }
}

export default ExchDetail
