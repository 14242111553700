class Order {
  constructor(
    coinigyOrderId,
    displayName,
    marketName,
    openTime,
    closeTime,
    exchange,
    limitPrice,
    stopPrice,
    baseCurrency,
    quoteCurrency,
    exchCode,
    orderType,
    quantity,
    status,
    authId,
    side
  ) {
    this.coinigyOrderId =
      typeof coinigyOrderId === 'undefined' ? '' : coinigyOrderId
    this.displayName = typeof displayName === 'undefined' ? '' : displayName
    this.marketName = typeof marketName === 'undefined' ? '' : marketName
    this.openTime = typeof openTime === 'undefined' ? '' : openTime
    this.closeTime = typeof closeTime === 'undefined' ? '' : closeTime
    this.exchange = typeof exchange === 'undefined' ? '' : exchange
    this.limitPrice = typeof limitPrice === 'undefined' ? '' : limitPrice
    this.stopPrice = typeof stopPrice === 'undefined' ? '' : stopPrice
    this.baseCurrency = typeof baseCurrency === 'undefined' ? '' : baseCurrency
    this.quoteCurrency =
      typeof quoteCurrency === 'undefined' ? '' : quoteCurrency
    this.exchCode = typeof exchCode === 'undefined' ? '' : exchCode
    this.orderType = typeof orderType === 'undefined' ? '' : orderType
    this.quantity = typeof quantity === 'undefined' ? '' : quantity
    this.status = typeof status === 'undefined' ? '' : status
    this.authId = typeof authId === 'undefined' ? '' : authId
    this.side = typeof side === 'undefined' ? '' : side
  }
}

export default Order
